import React, {useCallback} from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
    Button,
    ButtonType, EmailInput, FileInput, Form, IStringInputModel,
    PageContainer,
    PageHeader, TextAreaInput, TextInput,
} from "@reapptor-apps/reapptor-react-components";
import styles from "@/pages/JobPosting/JobPosting.module.scss";
import {BasePageParameters, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import Localizer from "@/localization/Localizer";
import {JobPostingData} from "@/models/cms/JobsResponse";
import ReactMarkdown from "react-markdown";
import FenixAppController from "@/pages/FenixAppController";
import User from "@/models/server/User";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";


export interface IJobPostingParams extends BasePageParameters {
    title: string;
}

interface JobPostingState {
    jobPosting: JobPostingData | null
}

export default class JobPosting extends AnonymousPage<IJobPostingParams> {
    state: JobPostingState = {
        jobPosting: null,
    };
    public email: IStringInputModel = {value: ""};
    public phone: IStringInputModel = {value: ""};
    public firstname: IStringInputModel = {value: ""};
    public lastname: IStringInputModel = {value: ""};
    public message: IStringInputModel = {value: ""};
    public attachments: FileModel[] = [];
    public formRef: React.RefObject<Form> = React.createRef();


    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        //const params = this.parameters as IBlogPostProps as IBlogPostProps | null;
        const params = this.parameters as IJobPostingParams as IJobPostingParams;
        if (params) {
            const jobPostingSlug: string = params.title;

            const jobPosting: any = await this.getAsync(`/api/Application/GetJobPosting?&populate=*&jobPostingSlug=${jobPostingSlug}`);
            await this.setState({jobPosting: jobPosting.data[0]})
        }
        const user: User | null = this.findUser();
        if (user) {
            this.email.value = user.email || "";
            this.phone.value = user.phone || "";
            this.firstname.value = user.firstname || "";
            this.lastname.value = user.lastName || "";
        }
        window.scrollTo(0, 0);
    }
    
    public async checkFiles(): Promise<boolean> {
        if(this.attachments.length > 0) {
            for(const file of this.attachments){
                if(file.size > 2097152){
                    return false;
                }
            }
            return true;
        }
        //Because no attachments, prevent sending
        return false;
    }


    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        const JobApplication = {} as any;
        if(this.formRef.current){
            const valid: boolean = await this.formRef.current.validateAsync();
            const filesValid: boolean = await this.checkFiles();
            if(valid && filesValid){
                //Send
                data.keys().map((key) => {
                    return (JobApplication[key] = data.getValue(key));
                });
                JobApplication.Attachments = this.attachments;
                JobApplication.JobTitle = this.state.jobPosting!.attributes.title;
                await this.postAsync("api/JobApplication/JobApplication", JobApplication);
            }
            else{
                // :(
            }
        }
    }

    public async onFileChange(file: FileModel[]): Promise<void> {
        this.attachments.push(file[0]);
    }
    
    public async onFileRemove(file: FileModel): Promise<void> {
        this.attachments.remove(file);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader title={Localizer.jobsPageTitle} className={styles.pageHeader}/>
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        {this.state.jobPosting &&
                            <>
                                <div className={styles.jobPostingBackButtonDiv}>
                                    <Button className={styles.textButton} type={ButtonType.Text} label={`« ${Localizer.genericBack}`} onClick={async () => history.back()}/>
                                </div>
                                <h2>{this.state.jobPosting.attributes.title}</h2>
                                <div className={styles.jobPostingDiv}>
                                    <ReactMarkdown>{this.state.jobPosting.attributes.description}</ReactMarkdown>
                                </div>
                                <div className={styles.jobPostingContactDiv}>
                                    {/*<h3>{Localizer.jobsPageEmailMessagePart1} <a href={`mailto:${FenixAppController.fenixSupportEmail}`}>{FenixAppController.fenixSupportEmail}.</a> {Localizer.jobsPageEmailMessagePart2}</h3>*/}
                                    <div className={styles.box}>
                                        <h2>{Localizer.jobsPageApplicationForm}</h2>
                                        <Form className={styles.formNameFields} id="form" onSubmit={async (_, data) => await this.handleSubmitAsync(data)} ref={this.formRef}>
                                                <TextInput className={styles.nameInput} required id="firstName" label={Localizer.formInputFirstname} model={this.firstname}/>
                                                <TextInput className={styles.nameInput} required id="lastName" label={Localizer.formInputLastname} model={this.lastname}/>
                                            <EmailInput
                                                className={styles.wideInput}
                                                id="email"
                                                label={Localizer.formInputEmail}
                                                model={this.email}
                                                required
                                                readonly={!!this.email.value}
                                            />
                                            <TextInput className={styles.wideInput} id="phone" label={Localizer.formInputPhone} model={this.phone}/>
                                            <TextAreaInput className={styles.wideInput} id="message" label={Localizer.formInputMessage} model={this.message} placeholder={Localizer.jobApplicationMessagePlaceholder} required rows={6}/>
                                            <FileInput className={styles.wideInput}
                                                       dropZone
                                                       multiple
                                                       required
                                                       maxSize={2097152}
                                                       maxTotalSize={2097152}
                                                       fileTypes={[".pdf", ".doc", ".docx"]}
                                                       label={Localizer.formInputAttachments}
                                                       onChange={async (file, fileModel: FileModel[]) => await this.onFileChange(fileModel!)}
                                                       onRemove={async (file) => this.onFileRemove(file)}
                                                       placeholder={Localizer.jobsPageApplicationFormFileSizeAndTypeMessage}
                                            />
                                            <div className={styles.jobApplicationFormTip}>
                                                <h3>{Localizer.jobsPageApplicationFormTip}</h3>
                                            </div>
                                            <Button  submit className={styles.button} type={ButtonType.Orange} label={Localizer.formSend}/>
                                        </Form>
                                    </div>
                                </div>
                            </>}
                    </div>
                </div>
            </PageContainer>

        )
    }
}