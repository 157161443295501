import React from "react";
import {Button, ButtonType} from "@reapptor-apps/reapptor-react-components";
import {LoadingSpinner} from '@/components/LoadingSpinner/LoadingSpinner';
import styles from "./PillButtonGroup.module.scss";
import Localizer from "@/localization/Localizer";

export interface PillButtonGroupPagination<T> {
    items: T[];
    loading: boolean;
    pageSize: number;
    pageIndex: number;
    totalCount: number;
}
export const PillButtonGroup = <T,>(props: {
    pagedData: PillButtonGroupPagination<T>;
    label: (button: T) => string;
    isSelected: (button: T) => boolean;
    onClick: (button: T) => void;
    onShowMoreClick: () => void;
}) => {
    const {isSelected, pagedData, label, onClick, onShowMoreClick} = props;
    const {items, loading, pageSize, pageIndex, totalCount} = pagedData;

    const pageCount = Math.ceil((totalCount) / (pageSize));
    const visibleItemList = items.slice(0, pageSize * (pageIndex + 1));

    return (
        <div className={styles.pillButtonGroup}>
            {
                visibleItemList.map((button, index) => {
                    return <Button
                        key={index}
                        label={label(button)}
                        type={isSelected(button) ? ButtonType.Primary : ButtonType.Light}
                        onClick={async () => onClick(button)}
                    />
                })

            }
            <div className={styles.controlPanel} data-can-show-more={String((pageIndex + 1) < pageCount && pageSize < items.length)}>
                {!loading && <Button className={styles.showMore} label={Localizer.buttonShowAll.toUpperCase()} type={ButtonType.Text} onClick={async () => onShowMoreClick()}/>}
                {loading && <LoadingSpinner/>}
            </div>
        </div>
    );
};