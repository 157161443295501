import React from "react";
import {BasePageParameters, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import User from "../../models/server/User";
import {
    Button,
    ButtonType,
    EmailInput,
    Form,
    Icon,
    IconStyle,
    IStringInputModel,
    PageContainer,
    PageHeader,
    TextAreaInput,
    TextInput
} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";
import styles from "./ContactSupport.module.scss";
import PageDefinitions from "@/providers/PageDefinitions";
import FenixAppController from "@/pages/FenixAppController";
import {DynamicPageData, DynamicPageResponse, DynamicPageResponseSingleType} from "@/models/cms/DynamicPageResponse";

export interface IContactSupportParameters extends BasePageParameters {
    requestId: string | null;
}

interface IContactSupportState {
    locations: DynamicPageData[];
    contactPageImage: DynamicPageData | null;
}

export default class ContactSupport extends AnonymousPage<IContactSupportParameters, IContactSupportState> {
    state: IContactSupportState = {
        locations: [],
        contactPageImage: null
    };
    public email: IStringInputModel = {value: ""};
    public phone: IStringInputModel = {value: ""};
    public firstname: IStringInputModel = {value: ""};
    public lastname: IStringInputModel = {value: ""};
    public message: IStringInputModel = {value: ""};
    public formRef: React.RefObject<any> = React.createRef();

    public async initializeAsync(): Promise<void> {
        const response: DynamicPageResponse = await this.getAsync(
            "/api/Application/GetDynamicPageContent?contentType=locations&deepPopulate=openingHours&sort=externalId"
        );
        
        const contactPageImage: DynamicPageResponseSingleType = await this.getAsync(
          "/api/Application/GetDynamicPageContent?contentType=contact-page-image&populate=*"
        );
        
        if(contactPageImage && contactPageImage.data?.attributes?.image?.data){
            await this.setState({...this.state, contactPageImage: contactPageImage.data})
        }

        await this.setState({locations: response.data});
        const user: User | null = this.findUser();
        if (user) {
            this.email.value = user.email || "";
            this.phone.value = user.phone || "";
            this.firstname.value = user.firstname || "";
            this.lastname.value = user.lastName || "";
        }
        window.scrollTo(0,0);
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        const contactSupportRequest = {} as any;
        data.keys().map((key) => {
            return (contactSupportRequest[key] = data.getValue(key));
        });

        await this.postAsync("api/ContactSupport/ContactSupport", contactSupportRequest);
    }

    public get requestId(): string | null {
        return this.props.parameters != null ? this.props.parameters.requestId : null;
    }

    public getTitle(): string {
        return Localizer.topNavContactSupport;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader className={styles.pageHeader} title={Localizer.contactSupportPageTitle}/>

                <div className={styles.pageContent}>
                    <div className={this.css(styles.fluidContent, styles.container)}>
                        <div className={styles.box} style={{backgroundColor: "#FBF2E9"}}>
                            <div>
                                <h2>{Localizer.topNavAppointmentBooking}</h2>
                                <p>
                                    <Icon className={styles.phoneOrEmailIcon}
                                          name={"envelope"}
                                          style={IconStyle.Light}
                                    />
                                    <a href={`mailto:${FenixAppController.fenixSupportEmail}`}>{FenixAppController.fenixSupportEmail}</a>
                                </p>
                                {this.state.locations &&
                                    <>
                                    <div className={styles.phoneReservationContainer}>
                                        <div>
                                            <Icon name={"phone"}
                                                  className={styles.phoneOrEmailIcon}
                                                  style={IconStyle.Light}
                                            />
                                        </div>
                                        <div className={styles.phoneReservationRowContainer}>
                                            {this.state.locations.map((location) => {
                                                    return(
                                                        <div className={styles.phoneReservationRow}>
                                                            <span>{location.attributes.name}</span>
                                                            <a href={`tel:${location.attributes.phoneNumber?.replace(/\s+/g, '')}`}>
                                                                {location.attributes.phoneNumber}
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <p>{Localizer.contactPhoneOpeningHours}</p>
                                    <br/>
                                    </>
                                }

                                {this.state.contactPageImage && 
                                  <div className={styles.contactPageImageDiv}>
                                      <img src={this.state.contactPageImage.attributes?.image?.data?.attributes?.url} alt="Img"/>
                                  </div>
                                }

                                <p>
                                    <b>{Localizer.contactSupportPageElectronicBookingMessage}</b>
                                </p>
                                <Button
                                    label={Localizer.buttonGoToBooking}
                                    type={ButtonType.Orange}
                                    className={this.css(styles.button, styles.widthUnset)}
                                    onClick={async (sender, data) => FenixAppController.openAppointmentsPage()}
                                />
                                <h2>{Localizer.generalLocations}</h2>
                                <div className={styles.locationsContainer}>
                                    {this.state.locations &&
                                        this.state.locations.map((location) => {
                                            return (
                                                <div
                                                    className={styles.location}
                                                >
                                                    <h6 onClick={async () =>
                                                        await PageRouteProvider.redirectAsync(
                                                            PageDefinitions.location.route({
                                                                params: {selectedLocation: location.attributes.externalId}
                                                            })
                                                        )
                                                    }>{location.attributes.name}</h6>
                                                    <div>{location.attributes.address}</div>
                                                    <div>
                                                        {location.attributes.postalCode} {location.attributes.town}
                                                    </div>
                                                    <div className={styles.openingHoursContainer}>
                                                        <div className={styles.openingHoursTextDiv}>
                                                            {Localizer.locationPageOpeningHours}:
                                                        </div>
                                                        {location.attributes.openingHours && location.attributes.openingHours.TitleHoursPair.length > 0 &&
                                                            <div className={styles.openingHoursTable}>
                                                                {location.attributes.openingHours?.TitleHoursPair.map((hoursPair) => {
                                                                    return(
                                                                        <div className={styles.openingHoursRow}>
                                                                            <span>{hoursPair.Title}</span>
                                                                            <span>{hoursPair.Hours}</span>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        }
                                                        {(location.attributes.externalId === "2" && (!location.attributes.openingHours)) &&
                                                            <div>
                                                                <span>{Localizer.locationPageAccordingToAgreement}</span>
                                                            </div>
                                                        }
                                                        {(location.attributes.phoneNumber && location.attributes.phoneNumber.length > 0) &&
                                                            <div className={styles.phoneNumber}>
                                                                <span><Icon name='phone' style={IconStyle.Light}/></span>
                                                                <span>
                                                                    <a href={`tel:${location.attributes.phoneNumber?.replace(/\s+/g, '')}`}>
                                                                        {location.attributes.phoneNumber}
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className={styles.box} style={{backgroundColor: "#FFF9F4"}}>
                            <h2 className={styles.contactSupportHeader}>{Localizer.contactSupportPageContactForm}</h2>
                            <Form id="form" onSubmit={async (_, data) => await this.handleSubmitAsync(data)} ref={this.formRef}>
                                <EmailInput
                                    id="email"
                                    label={Localizer.formInputEmail}
                                    model={this.email}
                                    required
                                    readonly={!!this.email.value}
                                />
                                <TextInput id="phone" label={Localizer.formInputPhone} model={this.phone}/>
                                <TextInput id="firstname" label={Localizer.formInputFirstname} model={this.firstname}/>
                                <TextInput id="lastname" label={Localizer.formInputLastname} model={this.lastname}/>
                                <TextAreaInput id="message" label={Localizer.formInputMessage} model={this.message} required rows={6}/>

                                <Button submit className={styles.button} type={ButtonType.Orange} label={Localizer.formSend}/>
                            </Form>
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}
