export default class LoginRequest {
    public firstName: string;

    public lastName: string;

    public ssn: string;

    public occupationalHealth: boolean;


    constructor(firstName: string, lastname: string, ssn: string, occupationalHealth: boolean) {
        this.firstName = firstName;
        this.lastName = lastname;
        this.ssn = ssn;
        this.occupationalHealth = occupationalHealth;
    }
}