import React from "react";
import {ch} from "@reapptor-apps/reapptor-react-common";
import {Button, EmailInput, Inline, PageContainer, PageHeader, PageRow, PhoneInput} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import TestPdfResponse from "@/models/server/responses/TestPdfResponse";
import TestSmsResponse from "@/models/server/responses/TestSmsResponse";
import TestEmailResponse from "@/models/server/responses/TestEmailResponse";

import Localizer from "../../localization/Localizer";

import styles from "./AdminConsole.module.scss"

interface IAdminConsoleState {
    phone: string;
    email: string;
}

export default class AdminConsole extends AuthorizedPage<{}, IAdminConsoleState> {

    state: IAdminConsoleState = {
        phone: this.getUser().phone,
        email: this.getUser().email
    };

    private async setPhoneAsync(phone: string): Promise<void> {
        await this.setState({phone});
    }

    private async setEmailAsync(email: string): Promise<void> {
        await this.setState({email});
    }

    private async clearRedisCacheAsync(): Promise<void> {
        await this.postAsync("api/adminConsole/clearRedisCache");
    }

    private async clearRedisSessionAsync(): Promise<void> {
        await this.postAsync("api/adminConsole/clearRedisSession");
        await ch.refresh();
    }

    private async testPdfAsync(): Promise<void> {
        const response: TestPdfResponse = await this.postAsync("api/adminConsole/testPdf");

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestPdfSuccess.format(response.version), true);

            if (response.pdf) {
                ch.download(response.pdf);
            }

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestPdfFailed.format(response.version, response.error));

        }
    }

    private async testSmsAsync(): Promise<void> {
        const response: TestSmsResponse = await this.postAsync("api/adminConsole/testSms", this.state.phone);

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestSmsSuccess, true);

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestSmsFailed.format(response.error));

        }
    }

    private async testEmailAsync(): Promise<void> {
        const response: TestEmailResponse = await this.postAsync("api/adminConsole/testEmail", this.state.email);

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestEmailSuccess, true);

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestEmailFailed.format(response.error));

        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.adminConsole}>
                <PageHeader title={Localizer.topNavAdminManagement} subtitle={this.getContext().username}/>
                <PageRow>
                    <div className="col">

                        <Inline>

                            <Button icon={{name: "fad eraser"}}
                                    label={Localizer.adminConsolePageClearRedisCache}
                                    confirm={Localizer.adminConsolePageClearRedisCacheConfirm}
                                    onClick={() => this.clearRedisCacheAsync()}
                            />

                            <Button icon={{name: "fad skull-crossbones"}}
                                    label={Localizer.adminConsolePageDropUserSessions}
                                    confirm={Localizer.adminConsolePageDropUserSessionsConfirm}
                                    onClick={() => this.clearRedisSessionAsync()}
                            />

                        </Inline>

                        <Inline>

                            <Button icon={{name: "file-pdf"}}
                                    label={Localizer.adminConsolePageTestPdf}
                                    onClick={() => this.testPdfAsync()}
                            />

                        </Inline>

                        <Inline>

                            <PhoneInput id="test_sms"
                                        width={"14rem"}
                                        value={this.state.phone}
                                        onChange={async (sender, value) => this.setPhoneAsync(value) }
                            />

                            <Button icon={{name: "phone"}}
                                    label={Localizer.adminConsolePageTestSms}
                                    disabled={!this.state.phone}
                                    onClick={() => this.testSmsAsync()}
                            />

                        </Inline>

                        <Inline>

                            <EmailInput id="test_email"
                                        width={"14rem"}
                                        value={this.state.email}
                                        onChange={async (sender, value) => this.setEmailAsync(value) }
                            />

                            <Button icon={{name: "envelope-open"}}
                                    label={Localizer.adminConsolePageTestEmail}
                                    disabled={!this.state.email}
                                    onClick={() => this.testEmailAsync()}
                            />

                        </Inline>

                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}