import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageContainer, WidgetContainer, RouteWidget} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import athenaeumStyles from "../../theme/Athenaeum.module.scss";

interface IDashboardProps {
    name: string | null;
}

interface IDashboardState {
}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {

    public getManual(): string {
        return Localizer.dashboardGetManual;
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }

    public render(): React.ReactNode {

        return (
            <PageContainer transparent className={athenaeumStyles.pageContainer} alertClassName={athenaeumStyles.alert}>

                <WidgetContainer controller="Dashboard">

                    <RouteWidget icon={{ name: "users" }} route={PageDefinitions.contactSupportRoute} label={Localizer.topNavContactSupport} description={Localizer.dashboardPageContactSupportDescription} />

                    <RouteWidget icon={{ name: "users-cog" }} route={PageDefinitions.admin.route} label={Localizer.topNavAdmin} description={Localizer.dashboardPageAdminDescription} />

                </WidgetContainer>

            </PageContainer>
        );
    }
}