export enum LoginResultStatus {
    Success,

    NotFound,

    Deleted,

    Locked,

    /**
     * Token security stamp does not correspond to user security stamp
     */
    TokenInvalidSecurityStamp,

    /**
     * Token already used
     */
    TokenUsed,

    /**
     * Token invalid or expired
     */
    TokenInvalidOrExpired
}

export enum AppointmentFilterType {
    None= -1,
    MedicalField,
    Specialist,
    Location,
}

export enum WorkDayState {
    Normal = 0,

    /**
     * Sairas
     */
    SickLeave = 1,

    /**
     * Loma
     */
    Vacation = 2,

    /**
     * Vko lepo
     */
    WeeklyRest = 3,

    /**
     * Maksullinen vapaapäivä
     */
    PaidDayOff = 4,

    /**
     * Pekkanen
     */
    FlexHours = 5,
}

export enum UserRoleGroup {
    Admins,

    Managers,

    Employees,

    ContactPersons
}

export enum InvitationType {
    Invitation,

    ResetPassword,

    ForgotPassword
}

export enum NotificationType {
    Sms,

    Application,

    Push,

    Email
}

export enum AuthType {
    Email,

    Phone
}

export enum AuditTimestamp {
    CreatedAt,

    ModifiedAt
}

export enum SavePasswordResultStatus {
    Success,

    WeakPassword,

    WrongCurrent
}

export enum OrganizationContractLevel {
    Operator = 0,

    Company = 1,

    SubCompany = 2
}

export enum OrganizationContractType {
    Customer = 0,

    Subcontractor = 1
}