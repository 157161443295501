import React, {RefObject, useState} from "react";
import {Button, ButtonType, Form, IStringInputModel, TextInput} from "@reapptor-apps/reapptor-react-components";
import {Dictionary} from "typescript-collections";
import Localizer from "@/localization/Localizer";


export const LoginForm = <T, >(props: {
    formSubmit: (data: Dictionary<string, any>) => Promise<void>;
    loginRef?: RefObject<Form>;
}) => {

    const formRef: React.RefObject<any> = React.createRef();

    const [firstName, setFirstName] = useState<IStringInputModel>({value: ""});
    const [lastName, setLastName] = useState<IStringInputModel>({value: ""});
    const [ssn, setSsn] = useState<IStringInputModel>({value: ""});

    const {formSubmit, loginRef} = props;


    return (
        <Form id="form"
              onSubmit={async (_, data) => props.formSubmit(data)}
              ref={loginRef ? loginRef : null}
        >
            <TextInput required
                       maxLength={35}
                       id={"firstName"}
                       label={Localizer.formInputFirstname} model={firstName}
                       onChange={(sender, value: string) => {
                           setFirstName({value})
                           return Promise.resolve();
                       }
                       }
            />
            <TextInput required
                       maxLength={35}
                       id={"lastName"}
                       label={Localizer.formInputLastname} model={lastName}
                       onChange={(sender, value: string) => {
                           setLastName({value})
                           return Promise.resolve();
                       }
                       }
            />
            <TextInput required
                       maxLength={11}
                       id={"ssn"}
                       label={Localizer.formInputSSN} model={ssn}
                       onChange={(sender, value: string) => {
                           setSsn({value})
                           return Promise.resolve();
                       }
                       }
            />
            <Button submit label={Localizer.loginPageLoginButton} type={ButtonType.Orange}/>
        </Form>
    );
};