import React, {useEffect, useState} from "react";
import {
    AppointmentBookingServicePointList,
    AppointmentBookingServicePointListItemTemplateProps,
    AppointmentBookingServicePointListPagination,
    PossibleAppointment
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointList/AppointmentBookingServicePointList";
import styles from "./AppointmentBookingServicePointListWidget.module.scss";
import {Button, Icon, IconSize, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {PillButtonGroup, PillButtonGroupPagination} from "@/components/PillButtonGroup/PillButtonGroup";
import GetAppointmentsInternalRequest from "@/models/server/requests/GetAppointmentsInternalRequest";
import {ApiProvider, ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {AppointmentBookingServicePointFilterOnChange} from "@/pages/AppointmentBooking/AppointmentBookingServicePointFilter/AppointmentBookingServicePointFilter";
import app from "@/App";
import Localizer from "@/localization/Localizer";
import moment from "moment-timezone";
import PageDefinitions from "@/providers/PageDefinitions";
import { DateTime } from 'luxon';

interface Care {
    id: string;
    label: string;

    locationIds: string[];
}

interface Location {
    id: string;
    label: string;
    careIds: string[];
}

export interface AppointmentBookingServicePointListWidget {
    typeOfCareList: Care[];
    locationList: Location[];
    onReserveTimeClick: (appointment: PossibleAppointment) => void;

}


const ListItemTemplate = (props: AppointmentBookingServicePointListItemTemplateProps) => {
    const {Action, AvailableTime, Profile, ProfileWithAptType, item, index, isLastItemInVisibleList, SpecialistImage, ProfileNoPic, WeekDay, TimeAndDuration, AvailableDate} = props;
    return (
      <div>
          {
              !ch.mobile && (
              <div className={styles.listItemContainer} key={index} data-is-last-item-in-visible-list={String(isLastItemInVisibleList)}>
                  <div>
                      {AvailableTime}
                      <div className={styles.duration}>{item.durationInMin} min</div>
                  </div>
                  <div>
                      {ProfileWithAptType}
                  </div>
                  {Action}
              </div>
            )
          }
          {
              ch.mobile && (
              <div className={styles.mobileListItemDiv} data-is-last-item-in-visible-list={String(isLastItemInVisibleList)}>
                  <div className={styles.profileDiv}>
                      {SpecialistImage}
                  </div>
                  <div className={styles.profileDiv2}>
                      {ProfileNoPic}
                  </div>
                  <div className={styles.mobileTimeDiv}>
                      <div className={styles.mobileTimeInnerDiv}>
                          <div className={styles.timeAndDurationDiv}>
                              {TimeAndDuration}
                          </div>
                      {WeekDay}
                      {AvailableDate}
                      </div>
                  </div>
                  <div className={styles.mobileButtonDiv}>
                      {Action}
                  </div>
              </div>
            )
          }
        
      </div>
    );
}
export const AppointmentBookingServicePointListWidget = (props: AppointmentBookingServicePointListWidget) => {

    const [pagedLocationData, setPagedLocationData] = useState<PillButtonGroupPagination<Location>>({
        items: props.locationList,
        loading: false,
        pageIndex: 0,
        pageSize: 4,
        totalCount: 10
    });
    const [typeOfCarePagedData, setTypeOfCarePagedData] = useState<PillButtonGroupPagination<Care>>({
        items: props.typeOfCareList.filter(item => item.locationIds.includes(pagedLocationData.items[0].id) && item.id != 'ÖPUH'),
        loading: false,
        pageIndex: 0,
        pageSize: 3,
        totalCount: 10
    });
    const [pagedPossibleAppointments, setPagedPossibleAppointments] = useState<AppointmentBookingServicePointListPagination<PossibleAppointment>>({
        loading: false,
        pageIndex: 0,
        pageSize: 2,
        totalCount: 25,
        items: []
    });
    const [selectedTypeOfCare, setSelectedTypeOfCare] = useState<Care | null>(typeOfCarePagedData.items[0]);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(pagedLocationData.items[0]);
    const [date, setSelectedDate] = useState<Date>(moment().toDate());

    useEffect(() => {
        const fetchData = async () => {
            setPagedPossibleAppointments({
                ...pagedPossibleAppointments,
                loading: true,
            })
            let {appointments, data} = await getAppointments(selectedLocation, selectedTypeOfCare);
            setPagedPossibleAppointments({
                ...data,
                loading: false,
            })
        }
        fetchData();

    }, [date, selectedLocation, selectedTypeOfCare])


    async function getAppointments(location: Location | null = null, typeOfCare: Care | null = null) {
        const getDate = (hours = 0, min = 0) => {
            return DateTime.fromJSDate(date).set({ hour: hours, minute: min, second: 0, millisecond: 0 }).toUTC(0,{keepLocalTime: true}).toJSDate();
        }

        const request: GetAppointmentsInternalRequest = {
            dateRangeStart: getDate(0, 0),
            dateRangeEnd: getDate(0, 0),
            timeRangeStart: getDate(5, 0),
            timeRangeEnd: getDate(18, 0),
            selectedService: typeOfCare ? [typeOfCare.id] :  [],
            selectedPractitioners: [],
            selectedClinics: location ? [location.id] : selectedLocation ? [selectedLocation.id] : [],
            occupationalHealth: false,
        };

        const appointments: PossibleAppointment[] = await ApiProvider.postAsync("/api/Application/GetAvailableAppointments", request);
        
        let data: AppointmentBookingServicePointListPagination<PossibleAppointment> = {
            items: appointments.filter(appointment => appointment.speciality1 != 'ÖPUH' && appointment.speciality2 != 'ÖPUH' && appointment.speciality3 != 'ÖPUH'),
            loading: false,
            pageIndex: 0,
            pageSize: 4,
            totalCount: appointments.length
        };
        return {appointments, data};
    }

    return (
        <div className={styles.container}>
            <div className={styles.cardHeader}>
                <div>
                    <Icon name="clock" style={2} size={IconSize.Normal}/>
                    <span>{Localizer.appointmentPageTitle}</span>
                </div>
                <div>{Localizer.appointmentPageInstantlyFreeTimes}</div>
            </div>
            <div className={styles.pillButtonGroup}>
                <div>{Localizer.appointmentPageSpecialty}</div>
                <PillButtonGroup<Care>
                    pagedData={typeOfCarePagedData}
                    label={(item) => item.label}
                    isSelected={(item) => selectedTypeOfCare ? selectedTypeOfCare.id === item.id : false}
                    onClick={async (item: Care) => {
                        setSelectedTypeOfCare(item)
                    }}
                    onShowMoreClick={() => {
                        setTypeOfCarePagedData({
                            ...typeOfCarePagedData,
                            loading: true
                        });
                        //  Simulate data fetching.
                        setTimeout(() => {
                            setTypeOfCarePagedData({
                                ...typeOfCarePagedData,
                                loading: false,
                                pageSize: 2000,
                                pageIndex: typeOfCarePagedData.pageIndex + 1,
                                items: [...typeOfCarePagedData.items]
                            });
                        }, 20);
                    }}
                />
            </div>
            <div className={styles.pillButtonGroup}>
                <div>{Localizer.homePageHeaderLocation}</div>
                <PillButtonGroup<Location>
                    pagedData={pagedLocationData}
                    label={(item) => item.label}
                    isSelected={(item) => selectedLocation ? selectedLocation.id === item.id : false}
                    onClick={async (item) => {
                        await setSelectedLocation(item)

                        const caresArray: Care[] = props.typeOfCareList.filter(a => a.locationIds.includes(item.id));
                        setTypeOfCarePagedData({
                            ...typeOfCarePagedData,
                            items: caresArray,
                        })
                        if(selectedTypeOfCare && !caresArray.includes(selectedTypeOfCare)) {
                            setSelectedTypeOfCare(null);
                        }


                    }}
                    onShowMoreClick={() => {
                        setPagedLocationData({
                            ...pagedLocationData,
                            loading: true
                        });
                        //  Simulate data fetching.
                        setTimeout(() => {
                            setPagedLocationData({
                                ...pagedLocationData,
                                loading: false,
                                pageIndex: pagedLocationData.pageIndex + 1,

                                items: [...pagedLocationData.items]
                            });
                        }, 1000)
                    }}
                />
            </div>
            <div className={styles.date}>
                <Button icon={{name: 'chevron-left', style: 2, size: IconSize.Normal}}
                        disabled={date < new Date()}
                        onClick={
                            async () => {
                                let newDate = moment(date).add(-1, 'days').toDate();
                                await setSelectedDate(newDate)
                            }
                        }
                />

                <span className={styles.dateText}>{date.toLocaleString(ch.language, {weekday: 'long'})} {Utility.format("{0:dd.MM}", date)}</span>
                <Button icon={{name: 'chevron-right', style: 2, size: IconSize.Normal}}
                        onClick={
                            async () => {
                                let newDate = moment(date).add(1, 'days').toDate();
                                await setSelectedDate(newDate)
                            }
                        }
                />
            </div>
            <AppointmentBookingServicePointList
                ListItemTemplate={ListItemTemplate}
                pagedData={pagedPossibleAppointments}
                onShowMoreClick={async () => {
                    await PageRouteProvider.redirectAsync(PageDefinitions.appointmentBooking.route(
                        {
                            params: {
                                selectedDate: moment(date).utcOffset(6, true).toDate().toISODateString(),
                                selectedLocation: selectedLocation ? selectedLocation.id : pagedLocationData.items[0].id,
                                occupationalHealth: (selectedTypeOfCare && selectedTypeOfCare.id == 'TTL') ? 'true' : 'false',
                                ...(selectedTypeOfCare && {selectedCare: selectedTypeOfCare.id})
                            }
                        }));
                }}
                onReserveTimeClick={(appointment: PossibleAppointment) => {
                    props.onReserveTimeClick(appointment)
                }}
            />
        </div>
    );
}

