import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
	Form,
	PageContainer,
	PageHeader,
} from "@reapptor-apps/reapptor-react-components";
import ReactMarkdown from "react-markdown";
import styles from "./TermsOfUse.module.scss";
import Localizer from "@/localization/Localizer";
import {DynamicPageData, DynamicPageResponseSingleType} from "@/models/cms/DynamicPageResponse";


export interface ITermsOfUseParams {}
interface ITermsOfUseState {
	termsOfUse: DynamicPageData | null;
}

export default class TermsOfUse extends AnonymousPage {

	state: ITermsOfUseState = {
		termsOfUse: null
	};


	public formRef: React.RefObject<Form> = React.createRef();

	async initializeAsync(): Promise<void> {
		await super.initializeAsync();
		const response: DynamicPageResponseSingleType = await this.getAsync(
			"/api/Application/GetDynamicPageContent?contentType=terms-of-use&populate=*"
		);
		await this.setState({termsOfUse: response.data});
		window.scrollTo(0, 0);
	}


	public render(): React.ReactNode {

		return (
			<PageContainer fullWidth className={styles.pageContainer}>
				<PageHeader className={styles.pageHeader} title={Localizer.companyPageTermsOfUse}/>
				<div className={styles.pageContent}>
					<div className={styles.fluidContent}>
						{this.state.termsOfUse && (
							<ReactMarkdown>{this.state.termsOfUse.attributes.content}</ReactMarkdown>
						)}
					</div>
				</div>
			</PageContainer>
		);
	}
}