import React, {ReactNode} from "react";
import styles from "./AppointmentBookingServicePointList.module.scss";
import {Button, ButtonType} from "@reapptor-apps/reapptor-react-components";
import {ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {LoadingSpinner} from '@/components/LoadingSpinner/LoadingSpinner';
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";
import FenixAppController from "@/pages/FenixAppController";
import ReceptionImage from "../../../images/vastaanottoaika.svg"
import PhoneImage from "../../../images/puhelin.svg"
import CheckUpImage from "../../../images/terveytarkastus.svg"

export interface PossibleAppointment {
    id: string;
    weekDay: string;
    date: string;
    time: string;
    durationInMin: number;
    specialistName: string;
    comment: string;
    specialistCode: string;
    specialistImage: string;
    specialistSpeciality: string;
    specialistLocation: string;
    locationCode: string;
    speciality1: string;
    speciality2: string;
    speciality3: string;
    appointmentType: string;
}

export interface AppointmentBookingServicePointListPagination<T> {
    items: T[];
    loading: boolean;
    pageSize: number;
    pageIndex: number;
    totalCount: number;
}

export interface AppointmentBookingServicePointListItemTemplateProps {
    index: number;
    isLastItemInVisibleList: boolean;
    item: PossibleAppointment;
    Profile: JSX.Element;
    SpecialistImage: JSX.Element;
    AvailableDate: JSX.Element;
    AvailableTime: JSX.Element;
    WeekDay: JSX.Element;
    Location: JSX.Element;
    Action: JSX.Element;
    Name: JSX.Element;
    Speciality: JSX.Element;
    TimeAndDuration: JSX.Element;
    ProfileNoPic: JSX.Element;
    ProfileWithAptType: JSX.Element;
    AppointmentType: JSX.Element;
}

export interface AppointmentBookingServicePointListProp {
    children?: ReactNode;
    className?: string;
    pagedData: AppointmentBookingServicePointListPagination<PossibleAppointment>;
    ListItemTemplate?: (props: AppointmentBookingServicePointListItemTemplateProps) => JSX.Element;
    onShowMoreClick: () => Promise<void>;
    onReserveTimeClick: (appointment: PossibleAppointment) => void;
}

const ListItemDefaultTemplate = (props: AppointmentBookingServicePointListItemTemplateProps) => {
    const {Action, AppointmentType, AvailableDate, AvailableTime, Location, Profile, WeekDay, Name, Speciality, ProfileNoPic, SpecialistImage, TimeAndDuration, index, isLastItemInVisibleList, item} = props;
    return (
        <div className={styles.listItemContainer} key={index} data-is-last-item-in-visible-list={String(isLastItemInVisibleList)}>
            {
                !ch.mobile && (
                    <>
                        <div>
                            {WeekDay}
                            {AvailableDate}
                        </div>
                        <div>
                            {AvailableTime}
                            <div className={styles.duration}>{item.durationInMin} min</div>
                        </div>
                        <div className={styles.profileDiv}>
                            {Profile}
                        </div>
                        <div className={styles.locationDiv}>
                            {Location}
                            <div className={styles.appointmentType}>
                                {AppointmentType}
                            </div>
                        </div>
                        {Action}
                    </>
                )
            }
            {
                ch.mobile && (
                    <div className={styles.mobileListItemDiv}>
                        <div className={styles.profileDiv}>
                            {SpecialistImage}
                        </div>
                        <div className={styles.profileDiv2}>
                            {ProfileNoPic}
                        </div>
                        <div className={styles.mobileTimeDiv}>
                            {WeekDay}
                            {AvailableDate}
                            {TimeAndDuration}
                        </div>
                        <div className={styles.mobileButtonDiv}>
                            {Action}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export const getAppointmentTypeImage = (apptType: string) => {
    switch (apptType) {
        case 'Vastaanotto':
            return <img className={styles.appointmentTypeImg}
                       src={ReceptionImage}
                       alt="Appointment type image"
                       onError={(el) => el.currentTarget.src = ''}/>
        case 'Puhelinvastaanotto':
            return <img className={styles.appointmentTypeImg}
                        src={PhoneImage}
                        alt="Appointment type image"
                        onError={(el) => el.currentTarget.src = ''}/>
        case 'Terveystarkastus':
            return <img className={styles.appointmentTypeImg}
                        src={CheckUpImage}
                        alt="Appointment type image"
                        onError={(el) => el.currentTarget.src = ''}/>
        default:
            return null;
    }   
}
export const AppointmentBookingServicePointList = (props: AppointmentBookingServicePointListProp) => {
    const {children, pagedData, className, ListItemTemplate, onShowMoreClick, onReserveTimeClick} = props;
    const {loading, pageSize, pageIndex, totalCount, items} = pagedData;

    const pageCount = Math.ceil((totalCount) / (pageSize));

    const visibleItemList = items.slice(0, pageSize * (pageIndex + 1));
    return (
        <div className={[styles.container, className ?? ''].join(' ')}>
            {visibleItemList.map((item, index) => {
                const Name = (
                    <div className={styles.specialistName}>{item.specialistName ?? '-'}</div>
                );
                const Speciality = (
                    <div className={styles.specialistSpeciality}>{item.specialistSpeciality ?? '-'}</div>
                );
                const Location = (
                    <div className={styles.location}>{item.specialistLocation ?? '-'}</div>
                );
                const SpecialistImage = (
                    <img className={styles.specialistImage}
                         src={item.specialistImage}
                         alt="Specialist's profile picture"
                         onError={(el) => el.currentTarget.src = FenixAppController.defaultDoctorImage()}/>
                );
                const WeekDay = (
                    <div className={styles.weekDay}>{item.weekDay.toPascalCase() ?? '-'}</div>
                );
                const AvailableDate = (
                    <div className={styles.location}>{item.date ?? '--'}</div>
                );
                const AvailableTime = (
                    <div className={styles.time}>{item.time ?? '-'}</div>
                );
                const Action = (
                    <Button className={styles.action} label={Localizer.buttonBookAppointment} type={ButtonType.Primary}
                            onClick={async () => onReserveTimeClick(item)}/>
                );
                const AppointmentType = (
                  <div className={styles.appointmentType}>
                      <>
                          {getAppointmentTypeImage(item.appointmentType)}
                          {item.appointmentType ?? '-'}
                      </>
                  </div>
                );
                const Profile = (
                    <div className={styles.profile} onClick={async () => PageRouteProvider.redirectAsync(PageDefinitions.doctor.route({params: {selectedDoctor: item.specialistCode}}))}>
                        {SpecialistImage}
                        <div>
                            {Name}
                            {Speciality}
                            {Location}
                        </div>
                    </div>
                );
                const ProfileWithAptType = (
                  <div className={styles.profile} onClick={async () => PageRouteProvider.redirectAsync(PageDefinitions.doctor.route({params: {selectedDoctor: item.specialistCode}}))}>
                      {SpecialistImage}
                      <div>
                          {Name}
                          {Speciality}
                          {Location}
                          {AppointmentType}
                      </div>
                  </div>
                );
                const TimeAndDuration = (
                  <div className={styles.timeAndDuration}>
                      <div>{item.time ?? '-'}</div> <div>{item.durationInMin ?? '-'} min</div>
                  </div>
                );
                
                const ProfileNoPic = (
                  <div className={styles.profile} onClick={async () => PageRouteProvider.redirectAsync(PageDefinitions.doctor.route({params: {selectedDoctor: item.specialistCode}}))}>
                      <div>
                          {Name}
                          {Speciality}
                          {Location}
                          {AppointmentType}
                      </div>
                  </div>
                )

                const Template = ListItemTemplate ? ListItemTemplate : ListItemDefaultTemplate;

                return (
                    <Template
                        item={item}
                        isLastItemInVisibleList={visibleItemList.length - 1 === index}
                        key={index}
                        index={index}
                        WeekDay={WeekDay}
                        Action={Action}
                        Location={Location}
                        Profile={Profile}
                        SpecialistImage={SpecialistImage}
                        Name={Name}
                        Speciality={Speciality}
                        AvailableTime={AvailableTime}
                        AvailableDate={AvailableDate}
                        TimeAndDuration={TimeAndDuration}
                        ProfileNoPic={ProfileNoPic}
                        ProfileWithAptType={ProfileWithAptType}
                        AppointmentType={AppointmentType}
                    />);
            })}
            <div className={Utility.css(styles.controlPanel, styles.noTimes)} data-can-show-more={String(items.length === 0)}>
                {Localizer.appointmentPageNoFreeTimes.toUpperCase()}
            </div>
            <div className={styles.controlPanel} data-can-show-more={String((pageIndex + 1) < pageCount && items.length > 0)}>
                {!loading && <Button className={styles.showMore} label={Localizer.appointmentPageShowMoreTimes} type={ButtonType.Text} onClick={async () => onShowMoreClick()}/>}
                {loading && <LoadingSpinner/>}
            </div>
        </div>
    );
};