import React, {useCallback} from "react";
import styles from "@/pages/Recent/Recent.module.scss";
import {Button, ButtonType} from "@reapptor-apps/reapptor-react-components";
import {RecentPostHomeCard} from "@/components/News/News";
import Localizer from "@/localization/Localizer";
import FenixAppController from "@/pages/FenixAppController";

const RecentPostCard = (props: RecentPostHomeCard) => {
    return (
        <div className={styles.blogPost}>
            <div className={styles.blogPostImageDiv}>
                <img className={props.attributes?.image?.data ? styles.blogPostImage : styles.blogPostLogo} src={props.attributes?.image?.data?.attributes?.formats?.thumbnail?.url ?? FenixAppController.getFenixLogo()} alt={"Image"} />

            </div>
            <div className={styles.blogPostContent}>
                <h4>{props.attributes?.title ?? ''}</h4>
                {
                  props.attributes?.subtitle &&
                  <>
                    {props.attributes?.subtitle?.length > 150 ?
                      <h5>
                        {props.attributes?.subtitle.slice(0, 150) + '...'}
                      </h5>
                      :
                      <h5>
                        {props.attributes?.subtitle}
                      </h5>}
                  </>
                }
                <Button type={ButtonType.Text} label={`${Localizer.buttonReadMore} »`} onClick={async () => props.onClick?.()} />
            </div>
        </div>
    );
};

export default RecentPostCard;