import {RecentPostHomeCard} from "@/components/News/News";
import styles from "@/pages/Recent/Recent.module.scss";
import React, {useEffect, useState} from "react";
import RecentPostListItem from "@/pages/Recent/RecentPostListItem/RecentPostListItem";
import ReactPaginate from 'react-paginate';
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import {LoadingSpinner} from "@/components/LoadingSpinner/LoadingSpinner";
import {Button, ButtonType} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";
import FenixAppController from "@/pages/FenixAppController";

interface IPaginationData {
    pagination: {
        page: number;
        pageCount: number;
        pageSize: number;
        total: number;
    }
}


const RecentListView = ({getBlogs}:{getBlogs: Function}) => {
    const [blogPosts, setBlogPosts] = useState<RecentPostHomeCard[]>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(2);
    const [pageCount, setPageCount] = useState(1);
    const [paginationData, setPaginationData] = useState<IPaginationData | null>(null);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const getBlogList = async () => {
            setLoading(true);
            const blogs = await getBlogs(page, pageSize);
            setBlogPosts(blogs.data);
            setPaginationData(blogs.meta);
            setLoading(false);
        }
        getBlogList();
    }, [])
    
    const loadMoreBlogs = async () => {
        if(paginationData?.pagination){
            const moreBlogs = await getBlogs(paginationData.pagination.page+1, pageSize);
            setBlogPosts(blogPosts.concat(moreBlogs.data));
            setPaginationData(moreBlogs.meta);
        }
    }

    const openBlog = async (blogSlug: string) => {
        await PageRouteProvider.redirectAsync(PageDefinitions.blogpost.route({params: {post: blogSlug}}))
    }
    
    const handlePageClick = async (event: any) => {
        const blogs = await getBlogs(event.selected+1, pageSize);
        setBlogPosts(blogs.data);
        //setPaginationData(blogs.meta);
    }
    
    
    return(
        <>
            <div className={styles.blogContainer}>
                {(blogPosts && blogPosts?.length > 0 && !loading) &&
                    blogPosts.map((blog) => {
                        return(
                            <RecentPostListItem key={blog.id} blog={blog} openBlog={openBlog} />
                        )
                    })
                }
                {loading &&
                  <div className={styles.blogLoadingSpinner}>
                    <LoadingSpinner />
                  </div>
                  }
                {(!loading && (!blogPosts || blogPosts?.length == 0)) &&
                  <div className={styles.noRecentPostsDiv}>
                      <img src={FenixAppController.getFenixLogo()} alt="Fenix Logo"/>
                      <h4>{Localizer.recentComingSoon}</h4>
                  </div>
                }
            </div>
            <div className={styles.loadMoreContainer}>
                {(paginationData?.pagination && paginationData.pagination.page < paginationData.pagination.pageCount) ?
                  <>
                      <Button type={ButtonType.Primary} label={`${Localizer.buttonLoadMore}`} onClick={async () => await loadMoreBlogs()} />
                  </>
                : 
                  <>
                  </>
                }
            </div>
        </>
    )
}

export default RecentListView;