import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {List, PageContainer, PageHeader, PageRow, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {Doctor} from "@/models/cms/DoctorsResponse";
import MedicalField, {MedicalFieldsResponse} from "@/models/cms/MedicalFieldsResponse";
import styles from "./Specialists.module.scss";

interface ISpecialistsProps {
}

interface ISpecialistsState {
    medicalFields: MedicalField[]

    selectedMedicalField: MedicalField | null;
}

export default class Specialists extends AnonymousPage<ISpecialistsProps, ISpecialistsState> {
    state: ISpecialistsState = {
        medicalFields: [],
        selectedMedicalField: null
    };

    private readonly _listRef: React.RefObject<List<MedicalField>> = React.createRef();


    async initializeAsync(): Promise<void> {
        await super.initializeAsync();


        await this.setState({selectedMedicalField: null})
    }

    //sender: List<User>): Promise<User[]> {
    async getMedicalFields(sender: List<MedicalField>): Promise<MedicalField[]> {
        const response: MedicalFieldsResponse = await sender.getAsync('/api/Application/GetSpecialists');
        return response.data;
    }

    private get medicalField(): MedicalField | null {
        return this.state.selectedMedicalField;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>

                <PageHeader title={""}/>

                <PageRow>
                    <div className={"col-md-4"}>
                    <List ref={this._listRef}
                          transform={item => this.toMedicalFieldListItem(item)}
                          fetchItems={async (sender) => await this.getMedicalFields(sender)}
                          selectedItem={this.medicalField || undefined}
                          onChange={(_, item) => this.selectMedicalField(item)}
                    />
                    </div>

                    {
                        (this.state.selectedMedicalField != null && this.state.selectedMedicalField.attributes != null) && (
                            <div className={"col-md-8"}>
                                <div className={"row"}>
                                    {this.state.selectedMedicalField.attributes.description}
                                </div>
                                <div className={styles.doctorsContainer}>

                                    {
                                        (this.state.selectedMedicalField.attributes.laeaekaerits.data.map((doctor: Doctor) => (
                                                <div className={styles.card}>
                                                    {/*<img src={`http://localhost:1337${doctor.attributes.Picture.data.attributes.url}`}*/}
                                                    {/*     alt="Avatar" style={{width: '100%'}}/>*/}
                                                    <div className={styles.container}>
                                                    <h4><b>{doctor.attributes.Name}</b></h4>
                                                        <p>{doctor.attributes.title}</p>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </PageRow>

            </PageContainer>
        )
    }

    public toMedicalFieldListItem(item: MedicalField): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id.toString();
        selectedItem.text = item.attributes!.speciality;
        return selectedItem;
    }


    private async selectMedicalField(value: MedicalField | null): Promise<void> {
        await this.setState({selectedMedicalField: value})
    }
}