import React from "react";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Button, ButtonType, PageContainer, PageRow} from "@reapptor-apps/reapptor-react-components";
import UserNotificationMessage from "@/models/server/UserNotificationMessage";
import {IPagedList} from "@reapptor-apps/reapptor-toolkit";
import NotificationItem from "@/pages/Notifications/NotificationItem/NotificationItem";
import Localizer from "@/localization/Localizer";

import styles from "./Notifications.module.scss";

interface INotificationsProps {
}

interface INotificationsState {
    total: number,
    take: number,
    notifications: UserNotificationMessage[] | null;
}

export default class Notifications extends AuthorizedPage<INotificationsProps, INotificationsState> {

    state: INotificationsState = {
        take: 10,
        total: 0,
        notifications: null
    };

    private async fetchDataAsync(): Promise<void> {
        const response: IPagedList<UserNotificationMessage> = await this.postAsync("/api/account/getMyNotifications", this.take);

        const notifications: UserNotificationMessage[] = response.items;

        await this.setState({notifications: notifications, total: response.totalItemCount});

        const readIds: string[] = notifications.where(item => !item.read).map(item => item.id);

        if (readIds.length > 0) {
            await this.postAsync("/api/account/markMyNotifications", readIds);
        }
    }

    private async showMoreAsync(): Promise<void> {
        this.state.take += 10;

        await this.fetchDataAsync();
    }

    private async openNotificationAsync(notification: UserNotificationMessage): Promise<void> {
        if (notification.pageRoute) {
            await PageRouteProvider.redirectAsync(notification.pageRoute);
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.fetchDataAsync();
    }

    public getTitle(): string {
        return Localizer.mobileNotificationsPageTitle;
    }

    public get notifications(): UserNotificationMessage[] | null {
        return this.state.notifications;
    }

    public get take(): number {
        return this.state.take;
    }

    public get total(): number {
        return this.state.total;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.pageContainer, styles.notifications, this.mobile && styles.mobile)}
                           alertClassName={styles.alert}
            >

                <PageRow>

                    <div className={styles.container}>

                        {
                            (this.notifications) &&
                            (
                                (this.notifications.length > 0)
                                    ?
                                    (
                                        <>

                                            {
                                                this.notifications.map((notification: UserNotificationMessage) => (
                                                    <NotificationItem key={notification.id}
                                                                      message={notification}
                                                                      onClick={() => this.openNotificationAsync(notification)}
                                                    />
                                                ))
                                            }

                                            {
                                                (this.take < this.total) &&
                                                (
                                                    <Button label={Localizer.genericShowMore}
                                                            type={ButtonType.Link}
                                                            className={styles.showMore}
                                                            onClick={() => this.showMoreAsync()}
                                                    />
                                                )
                                            }

                                        </>
                                    )
                                    :
                                    (
                                        <div className={styles.noNotifications}>
                                            <span>{Localizer.mobileNotificationsPageNoNotifications}</span>
                                        </div>
                                    )
                            )
                        }

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}