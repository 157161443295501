import React, {useCallback} from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
    Accordion,
    Button, ButtonType,
    List,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListGroup,
    SelectListItem
} from "@reapptor-apps/reapptor-react-components";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";
import styles from "@/pages/Recent/Recent.module.scss";
import {RecentPostAttributes, RecentPostHomeCard} from "@/components/News/News";
import RecentListView from "@/pages/Recent/RecentListView/RecentListView";
import {LoadingSpinner} from "@/components/LoadingSpinner/LoadingSpinner";
import {BasePageParameters, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import moment from "moment/moment";
import RecentPostCard from "@/pages/RecentPost/RecentPostCard/RecentPostCard";
import Localizer from "@/localization/Localizer";
import FenixAppController from "@/pages/FenixAppController";

export interface IBlogParams extends BasePageParameters {
    post: string;
}

interface IBlogPostState {
    blogPost: RecentPostHomeCard | null;
    img: string | null;
    date: string | null;
    blogs: RecentPostHomeCard[] | null,
    loading: boolean
   // blogPaginationData: IBlogPaginationData | null
}

interface IBlogPaginationData {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
}



export default class RecentPost extends AnonymousPage<IBlogParams, IBlogPostState> {
    state: IBlogPostState = {
        blogPost: null,
        img: null,
        date: null,
        blogs: null,
        loading: false
    };

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        this.setState({loading: true});
        window.scrollTo(0,0);
        const params = this.parameters as IBlogParams as IBlogParams;
        if (params){
            const blogSlug: string = params.post;

            //const blogPost: any = await this.getAsync(`/api/Application/GetSingleContent?populate=*&contentType=blog-posts&id=${blogPostId}`);
            const blogPost: any = await this.getAsync(`/api/Application/GetBlogPost?contentType=blog-posts&populate=*&blogSlug=${blogSlug}`);
            await this.setState({blogPost: blogPost.data[0], /*blogPaginationData: blogPost.meta.pagination*/})

            const blogPosts: any = await this.getAsync(`/api/Application/GetPaginatedContent?populate=*&contentType=blog-posts&page=1&pageSize=2&sort=publishedAt:desc`);

            this.setState({blogs: blogPosts.data});

            const img: string | null = this.state.blogPost?.attributes?.image?.data?.attributes?.formats?.large?.url ?
                this.state.blogPost?.attributes?.image?.data?.attributes?.formats?.large?.url
                : null;
            await this.setState({img})

            moment.locale(Localizer.language);
            const dateString = moment(this.state.blogPost?.attributes?.createdAt).format('l');
            await this.setState({date: dateString});
            await this.setState({loading: false});
        }

        

    }

    public openBlogPost = async (blogSlug: string) => {
        await PageRouteProvider.redirectAsync(PageDefinitions.blogpost.route({params: {post: blogSlug}}))
    }
    
    public openBlog = async () => {
        await PageRouteProvider.redirectAsync(PageDefinitions.blogRoute)
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                {this.state.loading ?
                <LoadingSpinner />
                :
                  <>
                <PageHeader title={Localizer.blogPageTitle} className={styles.pageHeader} />
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                {this.state.blogPost !== null ?
                    <div className={styles.blogPostViewContainer}>
                        <div className={styles.blogPostHeader}>
                            <h2>{this.state.blogPost?.attributes?.title}</h2>
                            <div className={styles.blogPostDate}>
                                <small>{this.state.date}</small>
                            </div>
                        </div>
                        <div className={styles.blogPostImage}>
                            {
                                <img src={this.state.img ?? FenixAppController.getFenixLogo()} alt={'Img'}/>
                            }
                        </div>
                        <div className={styles.blogPostContentPart}>
                            <ReactMarkdown>
                                {this.state.blogPost?.attributes?.text}
                            </ReactMarkdown>
                            <div className={styles.blogPostAuthorDiv}>
                                {this.state.blogPost?.attributes?.author &&
                                  `${Localizer.blogPostWrittenBy} ${this.state.blogPost?.attributes?.author}`
                                }
                            </div>
                        </div>
                        <hr />
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <h4>{Localizer.buttonReadMore}</h4>
                                <Button type={ButtonType.Text} label={`${Localizer.buttonShowAll} »`} onClick={this.openBlog} />
                                <div></div>
                            </div>
                            <div className={styles.blogPosts}>
                                {
                                    this.state.blogs == null
                                        ? <LoadingSpinner/>
                                        : (this.state.blogs ?? []).map((blogPost, index) => {
                                            return (
                                                <RecentPostCard key={blogPost.id}
                                                                id={blogPost.id}
                                                                attributes={blogPost.attributes}
                                                                onClick={async () => await PageRouteProvider.redirectAsync(PageDefinitions.blogpost.route({params: {post: blogPost?.attributes?.slug}}))}
                                                />
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null}
                </div>
                </div>
                  </>
                }
            </PageContainer>
                
        )
    }
}
