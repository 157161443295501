import React from "react";
import styles from "./LoadingSpinner.module.scss";

export const LoadingSpinner = (props: {className?: string}) => {
    return (
        <div className={`${styles.container} ${props.className ?? ''}`}>
            <span className={styles.spinnerBackground}/>
            <span className={styles.spinnerCircle}/>
        </div>
    );
};