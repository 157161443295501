import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import {RecentPostHomeCard} from "@/components/News/News";
import styles from "@/pages/Recent/Recent.module.scss";
import moment from "moment/moment";
import ReactMarkdown from "react-markdown";
import {Button, ButtonType} from "@reapptor-apps/reapptor-react-components";
import React from "react";
import Localizer from "@/localization/Localizer";
import FenixAppController from "@/pages/FenixAppController";


const RecentPostListItem = ({blog, openBlog}: {blog: RecentPostHomeCard, openBlog: Function}) => {
  moment.locale(Localizer.language);
    
    return (
        <div className={styles.blogPostListItem}>
            <div className={styles.blogPostContentDiv}>
              <div className={styles.blogPostContentTextDiv}>
                <div className={styles.blogHeaderDiv}>
                  <h4>{blog.attributes?.title ?? ''}</h4>
                  <div></div>
                </div>
                <div className={styles.blogSubtitleDiv}>
                  {
                    blog.attributes?.subtitle && blog.attributes.subtitle.length > 150 ?
                      <h5>
                        {blog.attributes.subtitle.slice(0, 150) + '...'}
                      </h5>
                      :
                      <h5>
                        {blog.attributes.subtitle}
                      </h5>
                  }
                </div>
                <div className={styles.blogDateAuthorDiv}>
                  <span>{blog.attributes?.createdAt ? `${moment(blog.attributes?.createdAt).format('l')}` : null}</span>
                  <span>{blog.attributes?.author ? `${Localizer.blogPostWrittenBy} ${blog.attributes?.author}` : null}</span>
                </div>
              </div>
              <div className={styles.blogPostButtonDiv}>
                <Button type={ButtonType.Primary} label={`${Localizer.buttonReadWholeArticle}`} onClick={async () => {openBlog(blog.attributes.slug)}} />
              </div>
            </div>
          <div className={styles.blogPostImageDiv}>
            <img className={blog.attributes?.image?.data ? styles.blogPostImage : styles.blogPostLogo} src={blog.attributes?.image?.data?.attributes?.formats?.small?.url ?? FenixAppController.getFenixLogo()} alt={"Image"} />
          </div>
        </div>
    )
}

export default RecentPostListItem;