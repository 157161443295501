import {INameExternalId} from "@/models/interfaces/INameExternalId";

export interface IDoctorOrCare extends INameExternalId {
    type: IDoctorOrCareType;
}

export enum IDoctorOrCareType{

    Doctor,
    Care

}