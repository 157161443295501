export default class Organization {
    public id: string = "";

    public name: string = "";

    public vatId: string = "";
    
    public externalId: string = "";
    
    public favorite: boolean = false;
    
    public isOrganization: boolean = true;
}