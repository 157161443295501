import React from "react";
import {ApiProvider, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Form, IconStyle, IStringInputModel, PageContainer, PageHeader, PageRow, PasswordInput, TextInput} from "@reapptor-apps/reapptor-react-components";
import LoginRequest from "../../models/server/requests/LoginRequest";
import {LoginResultStatus} from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../../localization/Localizer";

import styles from "./Login.module.scss";

export default class Login extends AnonymousPage {

    private _firstnameInput: IStringInputModel = {value: ""};
    private _lastnameInput: IStringInputModel = {value: ""};
    private _ssnInput: IStringInputModel = {value: ""};

    public _formRef: React.RefObject<any> = React.createRef();

    private async loginAsync(firstName: string, lastName: string, ssn: string): Promise<void> {

        const login = new LoginRequest(firstName, lastName, ssn, false);
        const loginResult: LoginResultStatus = await this.postAsync("api/Application/Login", login);

        if (loginResult !== LoginResultStatus.Success) {
            const form: Form | null = this._formRef.current;
            if (form != null) {
                const validationError: string = EnumProvider.getLoginResultStatusName(loginResult);
                await form.setValidationErrorsAsync(validationError);
            }
        }
    }

    public getTitle(): string {
        return Localizer.topNavLogin;
    }

    private async handleSubmitAsync(): Promise<void> {
        await this.loginAsync(this._firstnameInput.value, this._lastnameInput.value, this._ssnInput.value);
    }

    private async redirectToPasswordPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.forgotPasswordRoute);
    }

    private async onAzureClickAsync(): Promise<void> {
        window.location.href = await ApiProvider.invokeWithForcedSpinnerAsync(() => this.getAsync<string>("/api/Application/GetAzureSsoLogin"), true);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.loginPageTitle} subtitle={Localizer.loginPageSubtitle} className={styles.header}/>

                <PageRow>
                    <div className="col-lg-6">

                        <Form id="form" onSubmit={async () => await this.handleSubmitAsync()} ref={this._formRef}>

                            <TextInput required
                                       id="firstname"
                                       label={Localizer.formInputFirstname}
                                       model={this._firstnameInput}/>

                            <TextInput required
                                       id="password"
                                       label={Localizer.formInputLastname}
                                       model={this._lastnameInput}/>

                            <TextInput required
                                       id="ssn"
                                       label={"SSN"}
                                       model={this._ssnInput}/>


                            <ButtonContainer>

                                <Button type={ButtonType.Orange} label={Localizer.loginPageLoginButton} submit/>

                                <Button label={Localizer.loginPageForgotPasswordButton} onClick={() => this.redirectToPasswordPage()}/>

                            </ButtonContainer>

                        </Form>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}