import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
    Accordion, IAccordionClassNames,
    List,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListItem
} from "@reapptor-apps/reapptor-react-components";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";
import styles from "./Clinics.module.scss";
import FenixAppController from "@/pages/FenixAppController";
import Localizer from "@/localization/Localizer";

interface IClinicsProps {
    clinic: string;
}

export interface IClinicParams {
    clinic: string;
}

interface IClinicsState {
    clinics: DynamicPageData[];

    selectedClinic: DynamicPageData | null;
}

export default class Clinics extends AnonymousPage<IClinicsProps, IClinicsState> {
    state: IClinicsState = {
        clinics: [],
        selectedClinic: null
    };

    static accordionClassNames: IAccordionClassNames = {
        headerContainer: styles.accordionHeaderContainer
    };

    private readonly _listRef: React.RefObject<List<DynamicPageData>> = React.createRef();

    public toMedicalFieldListItem(item: DynamicPageData): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id.toString();
        selectedItem.text = item.attributes!.name;
        return selectedItem;
    }

    private async selectMedicalField(value: DynamicPageData | null): Promise<void> {
        await this.setState({selectedClinic: value});
    }

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const clinicHash = window.location.hash.replace('#', '');
        const response: DynamicPageResponse = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=clinics&deepPopulate=clinic_sub_items&deepPopulate2=icon");
        if (response.data) {
            await this.setState({selectedClinic: response.data[0]});
        }
        await this.setState({clinics: response.data, selectedClinic: response.data[0]});
        if(clinicHash){
            const selectClinic = response.data.find(item => item.id == parseInt(clinicHash))
            await this.setState({selectedClinic: selectClinic ?? response.data[0]});
        }
        window.scrollTo(0, 0);
    }

    static Item = (props: {name: string; isInner?: boolean; className?: string; onClick: () => void; imgSrc: string}) => {
        return (
          <button
            className={[styles.listItem, props.isInner ? styles.innerListItem : "", props.className ?? ""].join(" ")}
            onClick={async () => {
                props.onClick();
            }}
          >
              <img src={props.imgSrc} height={40} width={40} />
              <div>{props.name}</div>
          </button>
        );
    };


    private get selectedClinic(): DynamicPageData | null {
        return this.state.selectedClinic;
    }



    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader title={Localizer.clinicsPageTitle} className={styles.pageHeader}/>
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        <div className="row m-0 p-0">
                            <div className={"col-md-4"} style={{paddingTop: "3rem"}}>
                                {
                                    this.state.clinics && (
                                        this.state.clinics.map(item => {
                                            const subItems = item.attributes?.clinic_sub_items?.data ?? [];
                                            const selected = this.state.selectedClinic == item;
                                            return (
                                              <Accordion
                                                autoCollapse={false}
                                                className={this.css(
                                                  styles.listItemAccordion,
                                                  subItems.length === 0 && styles.accordionHideArrow,
                                                  selected && styles.selected
                                                )}
                                                header={
                                                    <Clinics.Item
                                                      name={item.attributes.name}
                                                      onClick={async () => {
                                                          await this.setState({selectedClinic: item});
                                                          setTimeout(() => {
                                                              window.location.hash = `${item.id}`;
                                                          }, 200);
                                                      }}
                                                      imgSrc={FenixAppController.getIcon(item)}
                                                    />
                                                }
                                              >
                                                  {subItems.length > 0 && (
                                                    <div className={styles.accordionListWrapper}>
                                                        {subItems.map((subItem) => {
                                                            const innerSelected = this.state.selectedClinic == subItem;

                                                            return (
                                                              <Accordion
                                                                autoCollapse={false}
                                                                className={this.css(
                                                                  styles.listItemAccordion,
                                                                  styles.accordionHideArrow,
                                                                  innerSelected && styles.selected
                                                                )}
                                                                header={
                                                                    <Clinics.Item
                                                                      isInner
                                                                      name={subItem.attributes.name}
                                                                      onClick={async () => {
                                                                          await this.setState({selectedClinic: subItem});
                                                                          setTimeout(() => {
                                                                              window.location.hash = `${subItem.id}`;
                                                                          }, 200);
                                                                      }}
                                                                      imgSrc={FenixAppController.getIcon(subItem)}
                                                                    />
                                                                }
                                                              />
                                                            );
                                                        })}
                                                    </div>
                                                  )}
                                              </Accordion>
                                            )
                                        })
                                    )
                                }

                            </div>

                            <div className={"col-md-8"} style={{paddingTop: "5rem", wordWrap: "break-word"}} id={String(this.state.selectedClinic?.id ?? "")}>
                                {this.state.selectedClinic && (
                                  <div translate="no">
                                      <ReactMarkdown>{this.state.selectedClinic.attributes.content}</ReactMarkdown>
                                      {this.state.selectedClinic.attributes.accordions &&
                                        this.state.selectedClinic.attributes.accordions.map((accordion) => {
                                            return (
                                              <Accordion
                                                header={accordion.title}
                                                autoCollapse={false}
                                                className={styles.clinicAccordion}
                                                classNames={Clinics.accordionClassNames}
                                              >
                                                  <ReactMarkdown>{accordion.content}</ReactMarkdown>
                                              </Accordion>
                                            );
                                        })}
                                  </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}
