import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {List, PageContainer, PageHeader} from "@reapptor-apps/reapptor-react-components";
import {DynamicPageData} from "@/models/cms/DynamicPageResponse";
import styles from "@/pages/Recent/Recent.module.scss";
import {RecentPostHomeCard} from "@/components/News/News";
import RecentListView from "@/pages/Recent/RecentListView/RecentListView";
import {LoadingSpinner} from "@/components/LoadingSpinner/LoadingSpinner";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";

interface IBlogProps {
    blogpostId: number;
}

interface IBlogState {
    blogs: any[];
}

export interface IRecentParams {}

export default class Recent extends AnonymousPage<IBlogProps, IBlogState> {
    state: IBlogState = {
        blogs: [],
    };

    private readonly _listRef: React.RefObject<List<DynamicPageData>> = React.createRef();

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        window.scrollTo(0,0);
    }
    
    public getBlogs = async (page: string, pageSize: string) =>{
        const blogs: any = await this.getAsync(`/api/Application/GetPaginatedContent?populate=*&contentType=blog-posts&page=${page}&pageSize=${pageSize}&sort=publishedAt:desc`);
        await this.setState({blogs: blogs});
        return(this.state.blogs);
    }
    
    public openBlog = async (blogId: number) => {
        await PageRouteProvider.redirectAsync(PageDefinitions.blogPostRoute(blogId))
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader title={Localizer.blogPageTitle} className={styles.pageHeader} />
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        {
                            this.state.blogs != null ?
                                <RecentListView getBlogs={this.getBlogs}/>
                            :
                                <LoadingSpinner />
                        }
                    </div>
                </div>
            </PageContainer>
        );
    }
}
