import React, {useEffect, useState} from "react";
import styles from "./Footer.module.scss";
import {Button, ButtonType, Icon} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";
import {ApiProvider, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";

interface FooterLocation {
    id: string;
    name: string;
    address: string;
    postalCode: string;
    city: string;
}

interface OpeningHoursData {
    titleMondayWednesdayThursday: string;
    titleTuesdayFriday: string;
    titleSaturday: string;
    hoursMondayWednesdayThursday: string;
    hoursTuesdayFriday: string;
    hoursSaturday: string;
    
}

export interface LocationsData {
    attributes: {
        name: string;
        externalId: string;
        address: string;
        postalCode: string;
        town: string;
        openingHours: LocationOpeningHoursObject | null;
        phoneNumber: string | null;
    }
}

interface LocationOpeningHoursObject {
    TitleHoursPair: TitleHoursPair[];
}

interface TitleHoursPair {
    Title: string;
    Hours: string;
}

interface FooterProps {
    locations?: LocationsData[];
    onLocationClick?: (location: FooterLocation) => void
    onViewAllOpeningTimesClick?: () => void
    
    phoneNumber?: string;
    openingTimes?: {
        title: string;
        timeRange: string;
    }[];
}

const defaultPhoneNumber = '020 144 1511';
const defaultLocations = [
    {
        attributes: {
            externalId: '1',
            name: 'Lääkärikeskus Fenix Porvoo',
            address: 'Piispankatu 22',
            town: 'Porvoo',
            postalCode: '06100',
            openingHours: {
                TitleHoursPair: [
                    {Title: "MA", Hours: "08.00-19.00"},
                    {Title: "TI", Hours: "08.00-19.00"},
                    {Title: "KE", Hours: "08.00-19.00"},
                    {Title: "TO", Hours: "08.00-19.00"},
                    {Title: "PE", Hours: "08.00-16.00"},
                    {Title: "LA", Hours: "10.00-14.00"},
                    {Title: "SU", Hours: "Suljettu"}
                ]
            },
            phoneNumber: '020 144 1511'
        }
    },
    {
        attributes: {
            externalId: '3',
            name: 'Lääkärikeskus Fenix Porvoo Tori',
            address: 'Rauhankatu 29',
            town: 'Porvoo',
            postalCode: '06100',
            openingHours: {
                TitleHoursPair: [
                    {Title: "MA", Hours: "08.00-19.00"},
                    {Title: "TI", Hours: "08.00-16.00"},
                    {Title: "KE", Hours: "08.00-17.00"},
                    {Title: "TO", Hours: "08.00-19.00"},
                    {Title: "PE", Hours: "08.00-15.00"},
                    {Title: "LA", Hours: "Suljettu"},
                    {Title: "SU", Hours: "Suljettu"}
                ]
            },
            phoneNumber: '020 144 1511'
        }
    },
    {
        attributes: {
            externalId: '2',
            name: 'Lääkärikeskus Fenix Helsinki',
            address: 'Puistokatu 3A',
            town: 'Helsinki',
            postalCode: '00140',
            openingHours: null,
            phoneNumber: '020 144 1511'
        }
    },
    {
        attributes: {
            externalId: '4',
            name: 'Lääkärikeskus Fenix Loviisa',
            address: 'Kuningattarenkatu 11',
            town: 'Loviisa',
            postalCode: '07900',
            openingHours: {
                TitleHoursPair: [
                    {Title: "MA", Hours: "08.00-16.00"},
                    {Title: "TI", Hours: "08.00-16.00"},
                    {Title: "KE", Hours: "08.00-16.00"},
                    {Title: "TO", Hours: "08.00-16.00"},
                    {Title: "PE", Hours: "08.00-15.00"},
                    {Title: "LA", Hours: "Suljettu"},
                    {Title: "SU", Hours: "Suljettu"}
                ]
            },
            phoneNumber: '020 144 1514'
        }
    }
];
const defaultOpeningTimes = [
    {
        title: Localizer.contactSupportPageMoWeTh,
        timeRange: '08:00-19:00',
    },
    {
        title: Localizer.contactSupportPageTuFr,
        timeRange: '08:00-16:00',
    },
    {
        title: Localizer.contactSupportPageSa,
        timeRange: '10:00-14:00',
    }
];
export const Footer = (props: FooterProps) => {
    const {locations, openingTimes, phoneNumber } = props;
    
    const [locationsData, setLocationsData] = useState<LocationsData[] | null>(null)
    
    useEffect(  () => {
        const fetchData = async () => {
            const locationData: any = await ApiProvider.getAsync("/api/Application/GetDynamicPageContent?contentType=locations&deepPopulate=openingHours&sort=externalId", null);
            if (locationData && locationData?.data) {
                setLocationsData(locationData.data)
            }
        }
        fetchData();
    }, [])
    
    const openLocation = async (id: string) => {
        await PageRouteProvider.redirectAsync(
            PageDefinitions.location.route({
                params: {selectedLocation: id}
            })
        )
    }

    return (
        <footer className={styles.container}>
            <div className={styles.content}>
                <div className={styles.locations}>
                    {
                        (locationsData ?? defaultLocations).map((location, index) => (
                            <div key={index} className={styles.location}>
                                <div>
                                    <Button
                                        type={ButtonType.Text}
                                        label={`Lääkärikeskus Fenix ${location.attributes.name} »`}
                                        onClick={async () => {
                                            location.attributes.externalId ?
                                            openLocation(location.attributes.externalId)
                                                :
                                                null
                                        }}
                                        className={styles.locationName}
                                    />
                                </div>
                                <div>{location.attributes.address}</div>
                                <div className={styles.locationAddress}>
                                    <span>{location.attributes.postalCode}</span>
                                    <span>{location.attributes.town}</span>
                                </div>
                                {(location.attributes.openingHours && location.attributes.openingHours?.TitleHoursPair?.length > 0) &&
                                    <div className={styles.locationOpeningHours}>
                                        <div>
                                            <span>{Localizer.locationPageOpeningHours}:</span>
                                        </div>
                                        <div className={styles.openingHoursTable}>
                                            {location.attributes.openingHours?.TitleHoursPair.map((hoursPair, index) => {
                                                return(
                                                    <div key={index} className={styles.openingHoursRow}>
                                                        <span>{hoursPair.Title}</span>
                                                        <span>{hoursPair.Hours}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                {(location.attributes.externalId === "2" && (!location.attributes.openingHours)) &&
                                    <div className={styles.locationOpeningHours}>
                                        <div>
                                            <span>{Localizer.locationPageOpeningHours}:</span>
                                        </div>
                                        <div>
                                            <span>{Localizer.locationPageAccordingToAgreement}</span>
                                        </div>
                                    </div>
                                }
                                {(location.attributes.phoneNumber && location.attributes.phoneNumber?.length > 0) && 
                                <div className={styles.phoneNumber}>
                                    <span><Icon name='phone'/></span>
                                    <span>{Localizer.footerPhoneShortened}</span>
                                    <span>
                                        <a href={`tel:${location.attributes.phoneNumber?.replace(/\s+/g, '')}`}>
                                            {location.attributes.phoneNumber}
                                        </a>
                                    </span>
                                </div>
                                }
                            </div>
                        ))
                    }
                </div>
                <div>
                    {Localizer.contactPhoneOpeningHours}
                </div>
            </div>
        </footer>
    );
};