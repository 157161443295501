import React from "react";
import ReactDOM from "react-dom";
import {PwaHelper} from "@reapptor-apps/reapptor-toolkit";
import App from "./App";

/* FA (Font Awesome Pro) */
import "./fonts/fa/fa.ts";
/* Bootstrap 4 */
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap";
/* JQuery required by bootstrap */
import "jquery";
/* Popper.js required by bootstrap */
import "popper.js";
/* App styles */
import "./theme/base.scss";
import "./theme/Athenaeum.module.scss";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
