import React, {RefObject, useEffect, useState} from "react";
import {Button, ButtonType, Form, IStringInputModel, TextInput} from "@reapptor-apps/reapptor-react-components";
import {Dictionary} from "typescript-collections";
import Localizer from "@/localization/Localizer";
import styles from './EditContactInfoForm.module.scss'
import FenixAppController from "@/pages/FenixAppController";
import { PhoneNumberUtil } from "google-libphonenumber"


export const EditContactInfoForm = (props: {
	formSubmit: (data: Dictionary<string, any>) => Promise<void>;
	editContactInfoFormRef: RefObject<Form>;
	cancelFunction: () => Promise<void>
}) => {

	const [email, setEmail] = useState<IStringInputModel>({value: FenixAppController.userContext.user?.email ?? ''});
	const [confirmEmail, setConfirmEmail] = useState<IStringInputModel>({value: ""});
	const [phone, setPhone] = useState<IStringInputModel>({value: FenixAppController.userContext.user?.phone ?? ''});
	const [confirmPhone, setConfirmPhone] = useState<IStringInputModel>({value: ""});

	const {formSubmit, editContactInfoFormRef} = props;
	
	const phoneUtil = PhoneNumberUtil.getInstance();

	const isEmail = (email: string) => {
		return (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/).test(email);
	}

	const isValidEmail = (string: string) => {
		return string.length === 0 || isEmail(string);
	}
	
	const isValidOrEmptyPhoneNumber = (string: string) => {
		if(string.length === 0){
			return true;
		}
		else{
			return (/(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/g).test(string);
		}
	}

	const checkFormValidity = () => {
		return (isValidEmail(email.value) && email.value.length > 0) && (isValidEmail(confirmEmail.value) && confirmEmail.value.length > 0)
			&& (email.value === confirmEmail.value) && ((phone.value.length > 0) && (confirmPhone.value.length > 0) && (phone.value === confirmPhone.value))
	}

	useEffect(() => {
		if(email.value.length > 0 && !isValidEmail(email.value)){
			editContactInfoFormRef!.current?.setValidationErrorsAsync(Localizer.emailFormEmailNotValid);
		}
		if((email.value.length > 0 && confirmEmail.value.length > 0) && email.value !== confirmEmail.value){
			editContactInfoFormRef!.current?.setValidationErrorsAsync(Localizer.emailFormEmailsDoNotMatch);
		}
		if((isValidEmail(email.value) && isValidEmail(confirmEmail.value)) && (confirmEmail.value.length === 0 || email.value === confirmEmail.value) && (isValidOrEmptyPhoneNumber(phone.value) && isValidOrEmptyPhoneNumber(confirmPhone.value)) && (confirmPhone.value.length === 0 || phone.value === confirmPhone.value)){
			editContactInfoFormRef!.current?.setValidationErrorsAsync();
		}

	}, [email, confirmEmail])

	useEffect(() => {
		if(phone.value.length > 0 && !isValidOrEmptyPhoneNumber(phone.value)){
			editContactInfoFormRef!.current?.setValidationErrorsAsync(Localizer.editContactInfoFormPhoneNumberNotValid);
		}
		if((phone.value.length > 0 && confirmPhone.value.length > 0) && phone.value !== confirmPhone.value){
			editContactInfoFormRef!.current?.setValidationErrorsAsync(Localizer.editContactInfoFormPhonesDoNotMatch);
		}
		if((isValidOrEmptyPhoneNumber(phone.value) && isValidOrEmptyPhoneNumber(confirmPhone.value)) && (confirmPhone.value.length === 0 || phone.value === confirmPhone.value) && (isValidEmail(email.value) && isValidEmail(confirmEmail.value)) && (confirmEmail.value.length === 0 || email.value === confirmEmail.value)){
			editContactInfoFormRef!.current?.setValidationErrorsAsync();
		}

	}, [phone, confirmPhone])


	return (
		<>
			<div>
				<p className={styles.headerSpan}><span>{Localizer.reserveTimeModalYourInformation}</span></p>
				<div className={styles.appointmentInfoDiv}>
					<p>{Localizer.formInputFirstname}:</p> <span>{FenixAppController.userContext.user?.firstname}</span>
					<p>{Localizer.formInputLastname}:</p> <span>{FenixAppController.userContext.user?.lastName}</span>
					<p>{Localizer.formInputSSN}:</p> <span>{FenixAppController.userContext.user?.username}</span>
				</div>
			</div>
			<Form id="form"
						onSubmit={async (_, data) => formSubmit(data)}
						ref={editContactInfoFormRef ? editContactInfoFormRef : null}
			>
				<TextInput required
									 id={"email"}
									 label={Localizer.formInputEmail} model={email}
									 onChange={async (sender, value: string) => {
										 setEmail({value})
									 }
									 }
				/>
				<TextInput required
									 id={"confirmEmail"}
									 label={Localizer.formInputEmailAgain} model={confirmEmail}
									 onChange={async (sender, value: string) => {
										 setConfirmEmail({value})
									 }
									 }
				/>
				<TextInput required
									 id={"phone"}
									 label={Localizer.formInputPhone} model={phone}
									 onChange={async (sender, value: string) => {
										 setPhone({value})
									 }
									 }
				/>
				<TextInput required
									 id={"confirmPhone"}
									 label={Localizer.formInputPhoneAgain} model={confirmPhone}
									 onChange={async (sender, value: string) => {
										 setConfirmPhone({value})
									 }
									 }
				/>
				<div className={styles.bottomButtonDiv}>
					<Button disabled={!checkFormValidity()} submit label={Localizer.formSave} type={ButtonType.Primary}/>
					<Button label={Localizer.genericCancel} type={ButtonType.Light} onClick={props.cancelFunction}/>
				</div>
			</Form>
		
		</>
		
	);
};