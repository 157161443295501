import React, {useEffect, useState} from "react";
import styles from "./HomePageCard.module.scss";
import {HomePageCard} from "@/models/cms/HomePageCard";
import ReactMarkdown from "react-markdown";
import {Button, ButtonType} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";

interface HomePageCardProps {
    homePageCard: HomePageCard
}

export const HomePageAdSpaceCard = (props: HomePageCardProps) => {
    const { homePageCard } = props;
    
    const getMainCardStyle = () => {
        switch(homePageCard.cardStyle){
            case 'imageOnly':
                return {background: `url(${homePageCard.backgroundImage.data[0].attributes.url}) no-repeat ${homePageCard.backgroundImagePosition ?? ''}`, backgroundSize: "contain", padding: "2rem", maxHeight: "500px", minHeight: "500px", width: "100%"}
            case 'blurredImageAndText':
                return {background: `linear-gradient(rgba(251,242,233,0.7), rgba(251,242,233,0.7)), url(${homePageCard.backgroundImage.data[0].attributes.url}) no-repeat ${homePageCard.backgroundImagePosition ?? ''}`, backgroundSize: "cover", padding: "2rem"}
            case 'imageAndText':
                return {backgroundColor: 'var(--fenix-appointment-booking-background)'}
        }
    }
    
    const redirectToPage = async (): Promise<void> => {
        window.location.href = homePageCard.buttonRedirectTo;
    }
    
    return(
        <>
            {homePageCard &&
                <div className={styles.homePageCard} style={getMainCardStyle()}>
                    {homePageCard.cardStyle === 'blurredImageAndText' &&
                        <>
                            <div className={styles.homePageCardContent}>
                                <div className={styles.homePageCardHeader}>
                                    <span>
                                        {homePageCard.header}
                                    </span>
                                </div>
                                <ReactMarkdown>{homePageCard.content}</ReactMarkdown>
                            </div>
                            {(homePageCard.buttonText && homePageCard.buttonRedirectTo != null) &&
                                <div className={styles.homePageCardButtonDiv}>
                                    <Button
                                        className={styles.homePageCardButton}
                                        type={ButtonType.Primary}
                                        label={homePageCard.buttonText}
                                        onClick={async () => redirectToPage()}
                                    ></Button>
                                </div>
                            }
                            <div />
                        </>
                    }
                    {homePageCard.cardStyle === 'imageAndText' &&
                        <div>
                            <div className={styles.imageDiv} style={{background: `url(${homePageCard.backgroundImage.data[0].attributes.url}) no-repeat ${homePageCard.backgroundImagePosition ?? ''}`, backgroundSize: "contain"}}>
                            </div>
                            <div className={styles.textContainer}>
                                <div className={styles.textDiv}>
                                    {homePageCard.header && 
                                    <div className={styles.homePageCardHeader}>
                                        <span>{homePageCard.header}</span>
                                    </div>
                                    }
                                    {homePageCard.content &&
                                    <div className={styles.homePageCardContent}>
                                        <ReactMarkdown>{homePageCard.content}</ReactMarkdown>
                                    </div>
                                    }
                                </div>
                                {(homePageCard.buttonText && homePageCard.buttonRedirectTo != null) &&
                                    <div className={styles.homePageCardButtonDiv}>
                                        <Button
                                            onClick={() => redirectToPage()}
                                            className={styles.homePageCardButton} type={ButtonType.Primary} label={homePageCard.buttonText}></Button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

