import React, {useCallback, useEffect, useState} from "react";
import {Button, DateInput, Dropdown, Icon, IconSize, IconStyle, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import styles from "./HomePageHeader.module.scss";
import {ILocation} from "@/models/interfaces/ILocation";
import {IDoctorOrCare} from "@/models/interfaces/IDoctorOrCare";
import FenixAppController from "@/pages/FenixAppController";
import Localizer from "@/localization/Localizer";

export interface HomePageHeaderProps {
    className?: string;
    onReserveTimeClick: () => void;
    onCallUsClick: () => void;
    onRemoteReceptionClick: () => void;
    onAppointmentClick: () => void;
    onSearch: (date: Date | null, selectedDoctorOrCare: any | null, selectedLocation: any | null) => void;
    locations: SelectListItem[];
    doctorsAndCares: SelectListItem[];
    selectedDate: Date | null;

    defaultDoctorOrCare: SelectListItem | null

    defaultLocation: SelectListItem | null,
    homePageTitle?: string | null

}

export const HomePageHeader = (props: HomePageHeaderProps) => {
    const {
        onCallUsClick,
        onAppointmentClick,
        onRemoteReceptionClick,
        onReserveTimeClick,
        onSearch,
        locations,
        doctorsAndCares,
        selectedDate,
        defaultLocation,
        defaultDoctorOrCare,
        homePageTitle
    } = props;

    const [locationsList, setLocationsList] = useState([...props.locations]);

    const [doctorAndCareList, setDoctorAndCareList] = useState([...props.doctorsAndCares]);

    const [location, setLocation] = useState(defaultLocation ?? null);

    const [date, setDate] = useState(selectedDate ?? null);


    const [doctorOrCare, setDoctorOrCare] = useState(defaultDoctorOrCare ?? null);

    useEffect(() => {
        setDoctorAndCareList([...props.doctorsAndCares]);
    }, [props.doctorsAndCares])


    useEffect(() => {
        setLocationsList([...props.locations]);
    }, [props.locations])


    useEffect(() => {
        // Todo: remove when placeholder parameter is supported by CL for DateInput.
        const input = document.getElementById("input_search_input");
        input?.setAttribute("placeholder", Localizer.genericToday);
    }, []);

    const locationChangedAsync = useCallback((locationSelectListItem: SelectListItem | null) => {

        const locationValue = locationSelectListItem as ILocation | null;

        setLocation(locationSelectListItem)
        if (!locationValue) {
            setDoctorAndCareList(doctorsAndCares);
            return;
        }

        const filteredDoctorsAndCares: SelectListItem[] = doctorAndCareList.filter(item => (item.ref as IDoctorOrCare).locationIds.includes(locationValue.id));

        setDoctorAndCareList(filteredDoctorsAndCares);
    }, [locationsList, locations]);

    const careOrDoctorChangedAsync = useCallback((doctorOrCareSelectListItem: SelectListItem | null) => {

        const doctorOrCareValue: IDoctorOrCare | null = doctorOrCareSelectListItem as IDoctorOrCare | null;
        setDoctorOrCare(doctorOrCareSelectListItem);
        if (!doctorOrCareValue) {
            setLocationsList(locations);
            return;
        }
        const filteredLocations: SelectListItem[] = locations.filter(item => doctorOrCareValue?.locationIds.includes(item.value));

        setLocationsList(filteredLocations)

    }, [doctorAndCareList, doctorsAndCares]);

    return (
        <div className={`${styles.HomePageHeader} ${props.className ?? ""}`}>
            <h2 className={styles.pageHeaderWelcomeText}>{homePageTitle ? homePageTitle : null}</h2>

            <div className={styles.content}>
                <div className={styles.buttonsContainer}>
                    <Button
                        icon={{name: "calendar-alt", size: IconSize.Large}}
                        className={styles.actionButton}
                        onClick={async () => onReserveTimeClick()}
                        label={Localizer.homePageHeaderBookATime}
                    />
                    <Button
                        icon={{name: "phone-alt", size: IconSize.Large}}
                        className={styles.actionButton}
                        onClick={async () => onCallUsClick()}
                        label={Localizer.homePageHeaderCallUs}
                    />
                    <Button
                        icon={{name: "headset", size: IconSize.Large}}
                        className={styles.actionButton}
                        onClick={async () => onRemoteReceptionClick()}
                        label={Localizer.homePageHeaderRemoteBooking}
                    />
                    <Button
                        icon={{name: "business-time", size: IconSize.Large, style: IconStyle.Light}}
                        className={styles.actionButton}
                        onClick={async () => FenixAppController.openOccupationalAppointments()}
                        label={Localizer.homePageHeaderOccupationalHealthBooking}

                    />
                </div>
                <div className={styles.inputsContainer}>
                    <Dropdown
                        toggleIcon={{name: "search"}}
                        nothingSelectedText={Localizer.homePageHeaderSearchForDoctor}
                        filterMinLength={1}
                        filterAutoFocus
                        items={doctorAndCareList ?? []}
                        selectedItem={doctorOrCare}
                        className={styles.locationDropdown}
                        onChange={async (sender, value: SelectListItem | null) => careOrDoctorChangedAsync(value)}
                    />
                    <div className={styles.inputSeparator}></div>
                    <Dropdown
                        toggleIcon={{name: "map-marker-alt"}}
                        nothingSelectedText={Localizer.homePageHeaderLocation}
                        items={locationsList ?? []}
                        selectedItem={location}
                        className={styles.locationDropdown}
                        onChange={async (sender, value: SelectListItem | null) => locationChangedAsync(value)}
                    />
                    <div className={styles.inputSeparator}></div>
                    <DateInput
                        id="search_input"
                        prepend={<Icon name="search"/>}
                        value={date}
                        onChange={async (date) => setDate(date)}
                        className={styles.dateInput}
                        inline
                    />
                    <Button
                        className={[styles.inputsContainerActionButton, styles.actionButton].join(" ")}
                        onClick={async () => onSearch(date, doctorOrCare, location)}
                        label={Localizer.genericSearch}
                    />
                </div>
            </div>
        </div>
    );
};
