import {Meta} from "@/models/cms/Meta";
import {Doctor, DoctorAttributes, DoctorsResponse} from "@/models/cms/DoctorsResponse";

export interface MedicalFieldsResponse {
    meta: Meta
    data: MedicalField[]
}

export default class MedicalField {

    id: number = 0;

    attributes: MedicalFieldAttributes | null = null;

    isMedicalField: true = true;

}

export interface MedicalFieldAttributes {
    createdAt: string
    updatedAt: string
    publishedAt: string
    speciality: string
    description: string
    laeaekaerits: DoctorsResponse
}