import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {List} from "@reapptor-apps/reapptor-react-components";
import {DynamicPageData} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";
import styles from "@/pages/Recent/Recent.module.scss";
import {RecentPostHomeCard} from "@/components/News/News";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import moment from "moment/moment";
import Localizer from "@/localization/Localizer";

interface IBlogPostProps {
    blogpostId: number;
}

interface IBlogPostState {
    blogPost: RecentPostHomeCard | null;
    img: string | null;
    date: string | null;
}



export default class RecentPostView extends AnonymousPage<IBlogPostProps, IBlogPostState> {
    state: IBlogPostState = {
        blogPost: null,
        img: null,
        date: null
    };

    private readonly _listRef: React.RefObject<List<DynamicPageData>> = React.createRef();

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const blogPost: any = await this.getAsync("/api/Application/GetSingleContent?populate=*&contentType=blog-posts");
        await this.setState({blogPost: blogPost.data})

        const img: string | null = this.state.blogPost?.attributes?.image?.data?.attributes?.formats?.large?.url ?
            blogPost?.attributes?.Image?.data?.attributes?.formats?.large?.url
            : null;

        moment.locale(Localizer.language);
        const dateString = moment(this.state.blogPost?.attributes.publishedAt).format('l');
        await this.setState({date: dateString});

    }

    public openBlog = async (blogId: number) => {
        await PageRouteProvider.redirectAsync(PageDefinitions.blogPostRoute(blogId))
    }

    public render(): React.ReactNode {
        return (
            <>
                {this.state.blogPost !== null ?
                    <div className={styles.blogPostViewContainer}>
                        <div className={styles.blogPostHeader}>
                            <h3>{this.state.blogPost?.attributes?.title}</h3>
                            <div className={styles.blogPostDate}>
                                <small>{this.state.date}</small>
                            </div>
                        </div>
                        <div className={styles.blogPostImage}>
                            {
                                this.state.img != null &&
                                <img src={this.state.img} alt={'title'}/>
                            }
                        </div>
                        <div className={styles.blogPostContent}>
                            <ReactMarkdown>
                                {this.state.blogPost.attributes?.text}
                            </ReactMarkdown>
                            <div className={styles.blogPostAuthorDiv}>
                                {this.state.blogPost.attributes?.author &&
                                    `${Localizer.blogPostWrittenBy} ${this.state.blogPost.attributes?.author}`
                                }
                            </div>
                        </div>
                        <div className={styles.blogPostFooter}>

                        </div>
                        <div>
                            Katso lisää
                        </div>


                    </div>
                    :
                    null}

            </>
        )
    }
}