import React, {useEffect, useState} from "react";
import {
	AppointmentBookingServicePointList,
	AppointmentBookingServicePointListItemTemplateProps,
	AppointmentBookingServicePointListPagination,
	PossibleAppointment
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointList/AppointmentBookingServicePointList";
import styles from "./PhoneBookingWidget.module.scss";
import {Button, Icon, IconSize, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {PillButtonGroup, PillButtonGroupPagination} from "@/components/PillButtonGroup/PillButtonGroup";
import GetAppointmentsInternalRequest from "@/models/server/requests/GetAppointmentsInternalRequest";
import {ApiProvider, ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import Localizer from "@/localization/Localizer";
import moment from "moment-timezone";
import PageDefinitions from "@/providers/PageDefinitions";
import {DateTime} from "luxon";

interface Care {
	id: string;
	label: string;

	locationIds: string[];
}

interface Location {
	id: string;
	label: string;
	careIds: string[];
}

export interface AppointmentBookingServicePointListWidget {
	typeOfCareList: Care[];
	locationList: Location[];
	onReserveTimeClick: (appointment: PossibleAppointment) => void;

}


const ListItemTemplate = (props: AppointmentBookingServicePointListItemTemplateProps) => {
	const {Action, AvailableTime, Profile, ProfileWithAptType, item, index, isLastItemInVisibleList, SpecialistImage, ProfileNoPic, WeekDay, TimeAndDuration, AvailableDate} = props;
	return (
		<div>
			{
				!ch.mobile && (
					<div className={styles.listItemContainer} key={index} data-is-last-item-in-visible-list={String(isLastItemInVisibleList)}>
						<div>
							{AvailableTime}
							<div className={styles.duration}>{item.durationInMin} min</div>
						</div>
						<div>
							{ProfileWithAptType}
						</div>
						{Action}
					</div>
				)
			}
			{
				ch.mobile && (
					<div className={styles.mobileListItemDiv} data-is-last-item-in-visible-list={String(isLastItemInVisibleList)}>
						<div className={styles.profileDiv}>
							{SpecialistImage}
						</div>
						<div className={styles.profileDiv2}>
							{ProfileNoPic}
						</div>
						<div className={styles.mobileTimeDiv}>
							<div className={styles.mobileTimeInnerDiv}>
								<div className={styles.timeAndDurationDiv}>
									{TimeAndDuration}
								</div>
								{AvailableDate}
							</div>
						</div>
						<div className={styles.mobileButtonDiv}>
							{Action}
						</div>
					</div>
				)
			}
		</div>
	);
}
export const PhoneBookingWidget = (props: AppointmentBookingServicePointListWidget) => {

	const [pagedLocationData, setPagedLocationData] = useState<PillButtonGroupPagination<Location>>({
		items: props.locationList,
		loading: false,
		pageIndex: 0,
		pageSize: 4,
		totalCount: 10
	});
	const [typeOfCarePagedData, setTypeOfCarePagedData] = useState<PillButtonGroupPagination<Care>>({
		items: props.typeOfCareList.filter(item => ['INFL'].includes(item.id)),
		loading: false,
		pageIndex: 0,
		pageSize: 3,
		totalCount: 10
	});
	const [pagedPossibleAppointments, setPagedPossibleAppointments] = useState<AppointmentBookingServicePointListPagination<PossibleAppointment>>({
		loading: false,
		pageIndex: 0,
		pageSize: 2,
		totalCount: 25,
		items: []
	});
	const [selectedTypeOfCare, setSelectedTypeOfCare] = useState<Care | null>(typeOfCarePagedData.items.filter(care => care.id == 'INFL')[0]);
	const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
	const [date, setSelectedDate] = useState<Date>(moment().toDate());

	useEffect(() => {
		const fetchData = async () => {
			setPagedPossibleAppointments({
				...pagedPossibleAppointments,
				loading: true,
			})
			let {appointments, data} = await getPhoneAppointments();
			setPagedPossibleAppointments({
				...data,
				loading: false,
			})
		}
		fetchData();

	}, [date, selectedLocation, selectedTypeOfCare])


	async function getPhoneAppointments() {
		const getDate = (hours = 0, min = 0) => {
			return DateTime.fromJSDate(date).set({ hour: hours, minute: min, second: 0, millisecond: 0 }).toUTC(0,{keepLocalTime: true}).toJSDate();
		}

		const request: GetAppointmentsInternalRequest = {
			dateRangeStart: getDate(0, 0),
			dateRangeEnd: getDate(0, 0),
			timeRangeStart: getDate(5, 0),
			timeRangeEnd: getDate(18, 0),
			selectedService: selectedTypeOfCare ? [selectedTypeOfCare.id] : [],
			selectedPractitioners: [],
			selectedClinics: ['1', '2', '3', '4'],
			occupationalHealth: false,
		};

		const appointments: PossibleAppointment[] = await ApiProvider.postAsync("/api/Application/GetAvailableAppointments", request);

		let data: AppointmentBookingServicePointListPagination<PossibleAppointment> = {
			items: appointments,
			loading: false,
			pageIndex: 0,
			pageSize: 4,
			totalCount: appointments.length
		};
		return {appointments, data};
	}

	return (
		<div className={styles.container}>
			<div className={styles.cardHeader}>
				<div>
					<Icon name="syringe" style={2} size={IconSize.Normal}/>
					<span>{Localizer.influenzaVaccinationWidgetTitle}</span>
				</div>
				<div>{Localizer.appointmentPageInstantlyFreeTimes}</div>
			</div>
			<div className={styles.pillButtonGroup}>
				<div>{Localizer.appointmentPageSpecialty}</div>
				<PillButtonGroup<Care>
					pagedData={typeOfCarePagedData}
					label={(item) => item.label}
					isSelected={(item) => selectedTypeOfCare ? selectedTypeOfCare.id === item.id : false}
					onClick={async (item: Care) => {
						setSelectedTypeOfCare(item)
					}}
					onShowMoreClick={() => {
						setTypeOfCarePagedData({
							...typeOfCarePagedData,
							loading: true
						});
						//  Simulate data fetching.
						setTimeout(() => {
							setTypeOfCarePagedData({
								...typeOfCarePagedData,
								loading: false,
								pageSize: 2000,
								pageIndex: typeOfCarePagedData.pageIndex + 1,
								items: [...typeOfCarePagedData.items]
							});
						}, 20);
					}}
				/>
			</div>
			<div className={styles.date}>
				<Button icon={{name: 'chevron-left', style: 2, size: IconSize.Normal}}
								disabled={date < new Date()}
								onClick={
									async () => {
										let newDate = moment(date).add(-1, 'days').toDate();
										await setSelectedDate(newDate)
									}
								}
				/>

				<span className={styles.dateText}>{date.toLocaleString(ch.language, {weekday: 'long'})} {Utility.format("{0:dd.MM}", date)}</span>
				<Button icon={{name: 'chevron-right', style: 2, size: IconSize.Normal}}
								onClick={
									async () => {
										let newDate = moment(date).add(1, 'days').toDate();
										await setSelectedDate(newDate)
									}
								}
				/>
			</div>
			<AppointmentBookingServicePointList
				ListItemTemplate={ListItemTemplate}
				pagedData={pagedPossibleAppointments}
				onShowMoreClick={async () => {
					await PageRouteProvider.redirectAsync(PageDefinitions.appointmentBooking.route(
						{
							params: {
								selectedDate: moment(date).utcOffset(6, true).toDate().toISODateString(),
								selectedLocation: selectedLocation ? selectedLocation.id : undefined,
								occupationalHealth: (selectedTypeOfCare && selectedTypeOfCare.id == 'TTL') ? 'true' : 'false',
								...(selectedTypeOfCare && {selectedCare: selectedTypeOfCare.id})
							}
						}));
				}}
				onReserveTimeClick={(appointment: PossibleAppointment) => {
					props.onReserveTimeClick(appointment)
				}}
			/>
		</div>
	);
}

