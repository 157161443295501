import React, {useCallback, useState} from 'react';
import {Button, ButtonType} from '@reapptor-apps/reapptor-react-components';
import {LoadingSpinner} from '@/components/LoadingSpinner/LoadingSpinner';
import styles from './News.module.scss';
import moment from "moment";
import 'moment/locale/fi'
import 'moment/locale/sv'
import { Masonry } from "react-plock";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";
import FenixAppController from "@/pages/FenixAppController";

export interface RecentPostHomeCard {
    id: number;
    attributes: RecentPostAttributes;
    onClick?: () => void
}

export interface RecentPostAttributes {
    id: number;
    image: {
        data: {
            attributes: {
                formats: {
                    small: {
                        url: string
                    },
                    thumbnail: {
                        url: string
                    },
                    large: {
                        url: string
                    },
                    url: string
                }
            }
        }
    };
    title: string;
    subtitle: string | null;
    text: string;
    publishedAt: Date;
    createdAt: Date;
    slug: string;
    author: string | null;
}

export interface FacebookPostCard {
    id: string;
    full_picture: string;
    message: string;
    permalink_url: string;
    created_time: Date;
    backdated_time: Date;
}

interface NewsProps {
    blogPostItems?: RecentPostHomeCard[];
    blogPostsLoading?: boolean;
    facebookPosts?: FacebookPostCard[];
    facebookPostsLoading?: boolean;
}

export const News = (props: NewsProps) => {

    const Header = useCallback((props: { title: string, action: string, onClick?: () => void }) => {
        return (
            <div className={styles.header}>
                <h4>{props.title}</h4>
                <Button type={ButtonType.Text} label={props.action} onClick={async () => props.onClick?.()}/>
                <div></div>
            </div>
        );
    }, []);

    const RecentPost = useCallback((props: RecentPostHomeCard) => {
        return (
            <div className={styles.blogPost}>
                <div className={styles.blogPostImageDiv}>
                  <img className={props.attributes?.image?.data ? styles.blogPostImage : styles.blogPostLogo} src={props.attributes?.image?.data?.attributes?.formats?.small?.url ?? FenixAppController.getFenixLogo()} alt={"Img"} />
                </div>
                <div className={styles.blogPostContent}>
                    <h4>{props.attributes?.title}</h4>
                    {
                      props.attributes?.subtitle && props.attributes.subtitle.length > 150 ?
                      <h5>
                        {props.attributes.subtitle.slice(0, 150) + '...'}
                      </h5>
                      :
                      <h5>
                        {props.attributes.subtitle}
                      </h5>
                    }
                    <Button type={ButtonType.Text} label={`${Localizer.buttonReadMore} »`} onClick={async () => props.onClick?.()} />
                </div>
            </div>
        );
    }, []);

    const FacebookPost = useCallback((props: FacebookPostCard) => {
        const [showMore, setShowMore] = useState(false);
        moment.locale(Localizer.language);
        const dateString = moment(props.created_time).fromNow();
        const dateStringUpperCase = dateString.charAt(0).toUpperCase() + dateString.slice(1);


        return (
            <div className={styles.facebookPost}>
                <div>
                    <small style={{opacity: 0.7}}>{dateStringUpperCase}</small>
                </div>
                <div className={styles.facebookPostImage}>
                    {props.full_picture &&
                        <img src={props.full_picture} alt={"Img"} />
                    }
                </div>
                <div className={styles.facebookPostContent}>
                    {props.message?.length > 200 ?
                        <>
                            <h5>{showMore ? props.message : props.message.slice(0, 200) + ' ...'}</h5>
                            <small onClick={() => setShowMore(!showMore)}>{showMore ? Localizer.buttonShowLess : Localizer.buttonShowMore}</small>
                        </>
                        :
                        <h5>{props.message}</h5>
                    }
                </div>
                <div className={styles.facebookPostLinks}>
                    <a href={props.permalink_url}>{Localizer.buttonViewOnFacebook}</a>
                </div>
            </div>
        );
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {props.blogPostsLoading
                    ? <LoadingSpinner/>
                    :
                    props.blogPostItems && props.blogPostItems?.length > 0 ?
                    <div>
                        <Header title={Localizer.blogPostsLatestBlogposts} action={`${Localizer.buttonShowAll} »`} onClick={async () => await PageRouteProvider.redirectAsync(PageDefinitions.blogRoute)} />
                        <div className={styles.blogPosts}>
                            {
                                props.blogPostsLoading
                                    ? <LoadingSpinner/>
                                    : (props.blogPostItems ?? []).map((blogPost, index) => {
                                        return (
                                            <RecentPost key={blogPost.id}
                                                      id={blogPost.id}
                                                      attributes={blogPost.attributes}
                                                      onClick={async () => await PageRouteProvider.redirectAsync(PageDefinitions.blogpost.route({params: {post: blogPost.attributes.slug}}))}
                                            />
                                        )
                                    })
                            }
                        </div>
                    </div>
                        : null
                }
                
                <div>
                    <Header title={Localizer.newsSocialMedia} action={`${Localizer.buttonViewOnFacebook} »`} onClick={() => window.open('https://www.facebook.com/laakarikeskusfenix', '_blank', 'noreferrer noopener')}/>
                    <div className={styles.facebookPosts}>
                        {props.facebookPostsLoading
                            ? <LoadingSpinner/>
                            :
                            <Masonry
                                items={props.facebookPosts ?? []}
                                render={(post, idx) => {
                                    return (
                                        <FacebookPost
                                            key={post.id}
                                            id={post.id}
                                            message={post.message}
                                            full_picture={post.full_picture}
                                            permalink_url={post.permalink_url}
                                            created_time={post.created_time}
                                            backdated_time={post.backdated_time}
                                        />
                                    )
                                }}
                                config={{
                                    columns: [1, 2, 3],
                                    gap: [8, 8, 8],
                                    media: [992, 1200, 1400],
                                }}/>
                        }
                        
                    </div>
                </div>
            </div>

        </div>
    );
};