import React from "react";
import {
    Button,
    ButtonType,
    Icon,
    IconSize,
    PageContainer,
    PageHeader,
    SelectListGroup,
    SelectListItem
} from "@reapptor-apps/reapptor-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Doctor} from "@/models/cms/DoctorsResponse";
import {HomePageHeader} from "@/pages/Home/HomePageHeader/HomePageHeader";
import {
    AppointmentBookingServicePointListWidget
} from "@/pages/Home/AppointmentBookingServicePointListWidget/AppointmentBookingServicePointListWidget";
import Localizer from "../../localization/Localizer";
import {RecentPostHomeCard, FacebookPostCard, News} from "@/components/News/News";
import styles from "./Home.module.scss";
import PageDefinitions from "@/providers/PageDefinitions";
import ReserveTimeModal from "@/components/ReserveTimeModal/ReserveTimeModal";
import FenixAppController from "@/pages/FenixAppController";
import {
    PossibleAppointment
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointList/AppointmentBookingServicePointList";
import {IMedicalField} from "@/models/interfaces/IMedicalField";
import {INameExternalId} from "@/models/interfaces/INameExternalId";
import {Care} from "@/models/interfaces/ICare";
import {ILocation} from "@/models/interfaces/ILocation";
import {IDoctor} from "@/models/interfaces/IDoctor";
import {IDoctorOrCare, IDoctorOrCareType} from "@/models/interfaces/IDoctorOrCare";
import {IAppointmentBookingParams} from "@/pages/AppointmentBooking/AppointmentBooking";
import {HomePageCard} from "@/models/cms/HomePageCard";
import {FenixSportCard} from "@/models/cms/FenixSportCard";
import ReactMarkdown from "react-markdown";
import {InfoBox} from "@/models/cms/InfoBox";
import {HomePageAdSpaceCard} from "@/components/HomePageCard/HomePageCard";
import {PhoneBookingWidget} from "@/pages/Home/PhoneBookingWidget/PhoneBookingWidget";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";

interface IHomeProps {
    name: string | null;
}

interface IHomeState {
    pwaInstall: boolean;
    doctors: Doctor[];
    facebookPostObject: FacebookPostCard[];
    blogPostObject: RecentPostHomeCard[];

    filterData: IFilterData | null;

    cares: Care[];
    locations: ILocation[];

    doctorsAndCares: IDoctorOrCare[];
    homePageCard: HomePageCard | null;
    fenixSportCard: FenixSportCard | null;
    homePageTitle: string | null;
    infoBox: InfoBox | null;
    showInfoBox: boolean;
    locationsData: DynamicPageData[];
}


interface IFilterData {
    medicalFields: IMedicalField[];
    doctors: IDoctor[];
    locations: INameExternalId[];
}


export default class Home extends AnonymousPage<IHomeProps, IHomeState> {

    private readonly _reserveTimeModal: React.RefObject<ReserveTimeModal> = React.createRef();

    state: IHomeState = {
        pwaInstall: false,
        doctors: [],
        facebookPostObject: [],
        blogPostObject: [],
        cares: [],
        locations: [],
        doctorsAndCares: [],
        filterData: null,
        homePageCard: null,
        fenixSportCard: null,
        homePageTitle: null,
        infoBox: null,
        showInfoBox: false,
        locationsData: []
    };

    private get reserveTimeModal(): ReserveTimeModal {
        return this._reserveTimeModal.current!;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        document.querySelector(".athenaeum-top-nav-navigation")?.setAttribute("blended", String(false));
        const dynamicData: IFilterData = await this.postAsync("/api/Application/GetFilterData", false);
        let cares: Care[] = dynamicData.medicalFields.map(x => {
            return {
                id: x.id,
                label: x.name,
                locationIds: x.locationIds,
                rank: x.rank
            }
        });

        let locations: ILocation[] = dynamicData.locations.map(x => {
            return {
                id: x.id,
                label: x.name,
                careIds: []
            }
        });

        let doctorsAndCares: IDoctorOrCare[] = dynamicData.doctors.map(x => {
            return {
                id: x.id,
                name: x.name,
                type: IDoctorOrCareType.Doctor,
                locationIds: x.locationIds
            }
        });

        doctorsAndCares = doctorsAndCares.concat(dynamicData.medicalFields.map(x => {
            return {
                id: x.id,
                name: x.name,
                type: IDoctorOrCareType.Care,
                locationIds: x.locationIds
            }
        }));

        await this.setState({filterData: dynamicData, cares, locations, doctorsAndCares: doctorsAndCares});

        const homePageTitle: any = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=home-page-title&populate=*");
        if (homePageTitle && homePageTitle?.data) {
            await this.setState({homePageTitle: homePageTitle.data.attributes?.title})
        }

        const infoBox: any = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=info-box&populate=*");
        if (infoBox && infoBox?.data?.attributes?.enabled) {
            await this.setState({infoBox: infoBox.data.attributes, showInfoBox: true})
        }
        
        const homePageCard: any = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=home-page-card&populate=backgroundImage&");
        if (homePageCard && homePageCard?.data) {
            await this.setState({homePageCard: homePageCard.data.attributes})
        }

        const locationsData: DynamicPageResponse = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=locations&deepPopulate=openingHours&sort=externalId");
        if (locationsData && locationsData?.data) {
            await this.setState({locationsData: locationsData.data})
        }

        const fenixSportCard: any = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=fenix-sport-card&populate=image&");
        if (fenixSportCard && fenixSportCard?.data) {
            await this.setState({fenixSportCard: fenixSportCard.data.attributes})
        }
        
        const facebookPosts: any = await this.getAsync('/api/Application/GetFacebookPosts');
        if (facebookPosts?.data) {
            await this.setState({facebookPostObject: facebookPosts.data})
        }
        const blogPosts: any = await this.getAsync(`/api/Application/GetPaginatedContent?populate=*&contentType=blog-posts&page=1&pageSize=2&sort=publishedAt:desc`);
        await this.setState({blogPostObject: blogPosts.data})

        
    }

    async componentWillUnmount(): Promise<void> {
        document.querySelector(".athenaeum-top-nav-navigation")?.setAttribute("blended", String(true));
        await super.componentWillUnmount();
    }
    
    async closeInfoBox(): Promise<void> {
        await this.setState({showInfoBox: false});
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }

    private get getTypeOfCareList() {
        return this.state.cares;
    }

    public toSelectListItem(item: ILocation): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.label;
        return selectedItem;
    }

    public toDoctorOrCareSelectListItem(item: IDoctorOrCare): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        selectedItem.group = item.type == IDoctorOrCareType.Doctor
            ? selectedItem.group = SelectListGroup.create("Hoitava asiantuntija")
            : selectedItem.group = SelectListGroup.create("Erikoisala")

        return selectedItem;
    }

    private get getLocationsSelectList(): SelectListItem[] {
        return this.state.locations ?
            this.state.locations.map(item => this.toSelectListItem(item))
            : [];
    }

    private get getDoctorsAndCaresSelectList(): SelectListItem[] {
        return this.state.doctorsAndCares ?
            this.state.doctorsAndCares.map(item => this.toDoctorOrCareSelectListItem(item))
            : [];
    }

    private get getLocationsList() {
        return this.state.locations;
    }
    
    private getHomePageCardStyle(){
        if(this.state.homePageCard && this.state.homePageCard.cardStyle === "imageOnly"){
            return { backgroundColor: 'var(--fenix-appointment-booking-background)', borderRadius: 'var(--border-radious-xl)'}
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.homePageContainer} alertClassName={styles.alert}>
                <PageHeader title="" className={styles.homePageHeader}>
                    <HomePageHeader
                        homePageTitle={this.state.homePageTitle ? this.state.homePageTitle : null}
                        defaultLocation={null}
                        defaultDoctorOrCare={null}
                        doctorsAndCares={this.getDoctorsAndCaresSelectList}
                        locations={this.getLocationsSelectList}
                        selectedDate={null}
                        onReserveTimeClick={async () => {
                            await PageRouteProvider.redirectAsync(PageDefinitions.appointmentBookingRoute)
                        }}
                        onCallUsClick={() => {
                            window.location.href = `tel:${FenixAppController.fenixSupportPhone}`;
                        }}
                        onRemoteReceptionClick={() => {
                            window.location.href = "https://fenix.movendosplatform.com/#login"
                        }}
                        onAppointmentClick={() => {
                        }}
                        onSearch={async (selectedDate, selectedDoctorOrCare: any | null, selectedLocation: any | null) => {
                            const casted: IDoctorOrCare | null = selectedDoctorOrCare as IDoctorOrCare | null;
                            const doctor: string | null = casted !== null ?
                                (casted).type === IDoctorOrCareType.Doctor
                                    ? casted.id
                                    : null
                                : null;
                            const care: string | null = casted !== null ?
                                (casted).type === IDoctorOrCareType.Care
                                    ? casted.id
                                    : null
                                : null;

                            const params: IAppointmentBookingParams = {} as any;
                            if (selectedLocation?.id) {
                                params.selectedLocation = selectedLocation.id
                            }
                            if (doctor) {
                                params.selectedDoctor = doctor;
                            }

                            if (care) {
                                params.selectedCare = care
                            }

                            if (selectedDate) {
                                params.selectedDate = selectedDate.toISODateString();
                            }

                            await PageRouteProvider.redirectAsync(PageDefinitions.appointmentBooking.route(
                                {
                                    params
                                }));

                        }}
                    />
                </PageHeader>
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        {(this.state.infoBox && this.state.showInfoBox) &&
                        <>
                            <div className={this.css(styles.infoBox, (this.state.infoBox.color === 'orange' ? styles.infoBoxPrimary : this.state.infoBox.color === 'red' ? styles.infoBoxDanger : styles.infoBoxSuccess))}>
                                <Icon name={"times"} size={IconSize.X2} style={2} customStyle={{position: "absolute", top: "1.5rem", right: "1.5rem", cursor: "pointer"}} onClick={() => this.closeInfoBox()}/>
                                <div className={styles.infoBoxIconDiv}>
                                    <Icon name={"exclamation-circle"} size={IconSize.X3} style={2}></Icon>
                                </div>
                                <div className={styles.infoBoxTextDiv}>
                                    {this.state.infoBox.title && 
                                    <div>
                                        <strong>{this.state.infoBox.title}</strong>
                                    </div>
                                    }
                                    {this.state.infoBox.content &&
                                    <div>
                                        <span>{this.state.infoBox.content}</span>
                                    </div>
                                    }
                                </div>
                            </div>
                        </>
                        }
                        
                        <div className={styles.cardAndBookingContainer}>
                            <div className={styles.homePageCardAndPhoneBookingContainer}>
                                {this.state.homePageCard &&
                                  <div className={styles.homePageCardContainer} style={this.getHomePageCardStyle()}>
                                    <HomePageAdSpaceCard homePageCard={this.state.homePageCard} />
                                  </div>
                                }
                                {
                                  this.state.filterData && (
                                    <div className={styles.homePagePhoneBookingContainer}>
                                        <PhoneBookingWidget
                                          typeOfCareList={this.getTypeOfCareList}
                                          locationList={this.getLocationsList}
                                          onReserveTimeClick={appointment => this.reserveTimeModal.openAsync(appointment, false, this.state.locationsData)}
                                        />
                                    </div>
                                  )
                                }
                            </div>

                            <div className={styles.bookingAndFenixSportContainer}>
                                <div className={styles.bookingWidget}>
                                    {
                                      this.state.filterData && (
                                        <AppointmentBookingServicePointListWidget
                                          typeOfCareList={this.getTypeOfCareList}
                                          locationList={this.getLocationsList}
                                          onReserveTimeClick={appointment => this.reserveTimeModal.openAsync(appointment, false, this.state.locationsData)}
                                        />
                                      )
                                    }

                                </div>
                                {this.state.fenixSportCard != null &&
                                      <div className={styles.fenixSportContainer}>
                                          <div className={styles.fenixSportHeaderContainer}>
                                              <div className={styles.fenixSportHeader}>
                                                  {this.state.fenixSportCard.header}
                                              </div>
                                          </div>
                                          <div className={styles.fenixSportTextAndButtonContainer}>
                                              <div className={styles.fenixSportContent}>
                                                  {this.state.fenixSportCard.content}
                                              </div>
                                              <div className={styles.fenixSportButtonContainer}>
                                                  <Button className={styles.fenixSportButton} type={ButtonType.Primary} label={Localizer.buttonGoToService}
                                                          onClick={async () => {
                                                              window.location.href = "https://www.fenixsport.fi/"
                                                          }}/>
                                              </div>
                                          </div>
                                          <div className={styles.fenixSportImgContainer}>
                                              <img src={this.state.fenixSportCard.image?.data?.attributes?.url} alt="Fenix Sport"/>
                                          </div>
                                          {/*<div className={styles.fenixSportTextContainer}>
                                              <div className={styles.fenixSportHeader}>
                                                  {this.state.fenixSportCard.header}
                                              </div>
                                              <div className={styles.fenixSportContent}>
                                                  {this.state.fenixSportCard.content}
                                              </div>
                                              <div className={styles.fenixSportButtonContainer}>
                                                  <Button className={styles.fenixSportButton} type={ButtonType.Primary} label={Localizer.buttonGoToService}
                                                          onClick={async () => {
                                                              window.location.href = "https://www.fenixsport.fi/"
                                                          }}/>
                                              </div>
                                          </div>
                                          <div className={styles.fenixSportImgContainer}>
                                              <img src={this.state.fenixSportCard.image?.data?.attributes?.url} alt="Fenix Sport"/>
                                          </div>*/}
                                      </div>
                                }
                            </div>
                            
                        </div>
                        
                    </div>
                    {this.state.fenixSportCard != null &&
                        <div className={styles.fluidContent}>
                            <div className={styles.fenixSportSecondContainer}>
                                <div className={styles.fenixSportTextContainer}>
                                    <div className={styles.fenixSportHeader}>
                                        {this.state.fenixSportCard.header}
                                    </div>
                                    <div className={styles.fenixSportContent}>
                                        {this.state.fenixSportCard.content}
                                    </div>
                                    <div className={styles.fenixSportButtonContainer}>
                                        <Button className={styles.fenixSportButton} type={ButtonType.Primary} label={Localizer.buttonGoToService}
                                        onClick={async () => {
                                            window.location.href = "https://www.fenixsport.fi/"
                                        }}/>
                                    </div>
                                </div>
                                <div className={styles.fenixSportImgContainer}>
                                    <img src={this.state.fenixSportCard.image?.data?.attributes?.url} alt="Fenix Sport"/>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={styles.wideContent}>
                        <News blogPostItems={this.state.blogPostObject} blogPostsLoading={false} facebookPosts={this.state.facebookPostObject} facebookPostsLoading={false}/>
                    </div>
                </div>

                <ReserveTimeModal ref={this._reserveTimeModal}
                                  onTimeReserved={(sender, time: PossibleAppointment) => FenixAppController.onReserveTime(time)}
                />

            </PageContainer>
        );
    }
}
