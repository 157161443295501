import React from "react";
import {BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";

import {Button, ButtonType, Icon, IconStyle, PageContainer, PageHeader} from "@reapptor-apps/reapptor-react-components";
import Map from "../../components/Map/Map"
import FenixAppController from "@/pages/FenixAppController";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";

import styles from "./Location.module.scss";
import Localizer from "@/localization/Localizer";

export interface ILocationParams extends BasePageParameters {
    selectedLocation: string;
}

interface ILocatioState {
    location: DynamicPageData | null;
}

export default class Location extends AnonymousPage<ILocationParams, ILocatioState> {

    state: ILocatioState = {
        location: null
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: DynamicPageResponse = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=locations&deepPopulate=openingHours");

        await this.setState({location: response.data.filter(item => item.attributes.externalId === this.parameters?.selectedLocation)[0]})
        window.scrollTo(0, 0)
    }


    public getTitle(): string {
        return this.state.location?.attributes.name ?? "";
    }


    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader className={styles.pageHeader} title={this.getTitle()}/>

                <div className={styles.pageContent}>
                    <div className={this.css(styles.fluidContent, styles.container)}>
                        <div className={styles.box} style={{backgroundColor: "#FBF2E9"}}>
                            {
                                this.state.location && (
                                    <>
                                        <ReactMarkdown>{this.state.location.attributes.info}</ReactMarkdown>
                                        <div className={styles.openingHoursTextDiv}>{Localizer.locationPageOpeningHours}:</div>
                                        <div className={styles.locationOpeningHours}>
                                            {(this.state.location.attributes.openingHours && this.state.location.attributes.openingHours?.TitleHoursPair?.length > 0) &&
                                                        <div className={styles.openingHoursTable}>
                                                            {this.state.location.attributes.openingHours?.TitleHoursPair.map((hoursPair) => {
                                                                return(
                                                                    <div className={styles.openingHoursRow}>
                                                                        <span>{hoursPair.Title}</span>
                                                                        <span>{hoursPair.Hours}</span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                            }
                                            {(this.state.location.attributes.externalId === "2" && (!this.state.location.attributes.openingHours)) &&
                                                <div>
                                                        <span>{Localizer.locationPageAccordingToAgreement}</span>
                                                </div>
                                            }
                                        </div>
                                        {(this.state.location.attributes.phoneNumber && this.state.location.attributes.phoneNumber?.length > 0) &&
                                            <div className={styles.phoneNumber}>
                                                <span><Icon name='phone' style={IconStyle.Light}/></span>
                                                <span>
                                                        <a href={`tel:${this.state.location.attributes.phoneNumber?.replace(/\s+/g, '')}`}>
                                                            {this.state.location.attributes.phoneNumber}
                                                        </a>
                                                    </span>
                                            </div>
                                        }
                                    </>
                                    
                                )
                            }

                            <Button label={Localizer.buttonGoToBooking}
                                    type={ButtonType.Orange}
                                    className={this.css(styles.button, styles.half)}
                                    onClick={async (sender, data) => FenixAppController.openAppointmentsPage(this.parameters?.selectedLocation)}
                            />
                        </div>
                        <div className={styles.box} style={{backgroundColor: "#FFF9F4"}}>
                            {
                                this.state.location && (
                                    <Map
                                        zoom={17}
                                        latitude={this.state.location.attributes.latitude}
                                        longitude={this.state.location.attributes.longitude}
                                        markerPosition={[this.state.location.attributes.latitude, this.state.location.attributes.longitude]}
                                    />
                                )
                            }
                        </div>


                    </div>
                    <div className={this.css(styles.fluidContent, styles.container)}>
                        <div className={"col-md-12"}>
                            {
                                this.state.location && (
                                    <ReactMarkdown>{this.state.location.attributes.content}</ReactMarkdown>
                                )
                            }

                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}
