import React, { useEffect } from "react";
import L, { LatLngExpression, Map as LeafletMap } from "leaflet";
import PropTypes from "prop-types";

import "leaflet/dist/leaflet.css";

interface MapProps {
    zoom: number;
    latitude: number;
    longitude: number;
    markerPosition?: [number, number];
}

const Map = ({ zoom, latitude, longitude, markerPosition = [latitude, longitude] }: MapProps) => {
    let map: LeafletMap;

    useEffect(() => {
        map = L.map("map").setView([latitude, longitude], zoom);

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution:
                'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        }).addTo(map);

        map.zoomControl.remove();
        map.attributionControl.remove();

        const markerIcon = L.icon({
            iconUrl: '/images/marker-icon-2x-blue.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
        });

        const markerObject = L.marker(markerPosition as LatLngExpression, { icon: markerIcon });

        if (markerPosition) {
            markerObject.addTo(map);
        }

        return () => {
            if (map) {
                map.remove();
            }
        };
    }, [zoom, latitude, longitude, markerPosition]);

    return <div id="map" style={{ width: "100%", height: "100%" }}></div>;
};

Map.propTypes = {
    zoom: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    markerPosition: PropTypes.arrayOf(PropTypes.number),
};

export default Map;
