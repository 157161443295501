import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {Button, ButtonType, PageContainer, PageHeader, PageRow} from "@reapptor-apps/reapptor-react-components";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import styles from "./Doctor.module.scss";
import {BasePageParameters, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import FenixAppController from "@/pages/FenixAppController";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import moment from "moment-timezone";

interface IDoctorProps {
}

interface IDoctorState {
    data: DynamicPageData[];
}

export interface IDoctorParams extends BasePageParameters {
    selectedDoctor: number | string;
}

export default class Doctor extends AnonymousPage<IDoctorProps, IDoctorState> {
    state: IDoctorState = {
        data: []
    };

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();


        const params = this.parameters as IDoctorParams as IDoctorParams | null;

        if (params) {
            const response: DynamicPageResponse = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=doctors&populate=*&filters=" + params?.selectedDoctor);
            response.data = response.data.filter(item => item.attributes.externalId === params?.selectedDoctor)
            await this.setState({data: response.data});
        }
        window.scrollTo(0, 0);

    }
    
    async redirectToBooking(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.appointmentBooking.route(
          {
              params: {
                  selectedDoctor: this.state.data[0].attributes.externalId,
                  ...(this.state.data[0].attributes.medical_fields?.data.find(medfield => medfield.attributes.externalId == "TTH") != undefined) && 
                  {occupationalHealth : "true"}
              }
          }))
    }

    public getTitle(): string {
        return Localizer.topNavDoctors;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader className={styles.pageHeader} title={this.getTitle()}/>
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        {this.state.data.length > 0 && (
                            <div>
                                <div className={styles.doctorContainer}>
                                    <div className={styles.doctorText}>
                                        <h3>{this.state.data.filter(item => item.attributes)[0].attributes.firstName} {this.state.data[0].attributes.lastName}</h3>
                                        <h6>{this.state.data[0].attributes.title}</h6>
                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{this.state.data[0].attributes.description}</ReactMarkdown>
                                        <div className={styles.redirectionButtonDiv}>
                                            <Button type={ButtonType.Primary} label={Localizer.buttonGoToBooking} onClick={() => this.redirectToBooking()}/>
                                        </div>
                                    </div>

                                    <img src={this.state.data[0]?.attributes?.image?.data?.attributes?.url ?? FenixAppController.defaultDoctorImage()} height={500}/>


                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </PageContainer>
        );
    }

}
