import React from "react";
import {PageRoute, ServerError, BasePageParameters, PageRouteProvider, ch} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {IContactSupportParameters} from "../ContactSupport/ContactSupport";
import {PageContainer, PageHeader, PageRow} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./Error.module.scss";

export interface IErrorPageParameters extends BasePageParameters {
    error: ServerError | null;
}

export default class ErrorPage extends AnonymousPage<IErrorPageParameters> {

    private get error(): ServerError | null {
        return (this.props.parameters != null) && (this.props.parameters.error != null)
            ? this.props.parameters.error
            : null;
    }

    private get isDevelopment(): boolean {
        return ch.isDevelopment;
    }

    private async redirectToContactPageAsync(): Promise<void> {
        const route = new PageRoute(PageDefinitions.contactSupportRouteName);
        if (this.error) {
            const parameters = {} as IContactSupportParameters;
            parameters.requestId = this.error!.requestId;
            route.parameters = parameters;
        }
        await PageRouteProvider.redirectAsync(route);
    }

    public render(): React.ReactNode {
        const error: ServerError | null = this.error;
        return (
            <PageContainer className={styles.error}>
                <PageHeader title={Localizer.errorPageTitle} subtitle={Localizer.errorPageErrorMessage} />

                <PageRow>
                    <div className="col">
                        {
                            (error) &&
                            (
                                <React.Fragment>
                                    <p>
                                        {Localizer.errorPageErrorMessage}
                                        &nbsp;
                                        <a href="javascript:void(0)" rel="noreferrer" onClick={() => this.redirectToContactPageAsync()}>{Localizer.errorPageContactSupport}</a>
                                    </p>

                                    {
                                        ((error.debugDetails) && (this.isDevelopment)) &&
                                        (
                                            <p className={styles.debugDetails}>{error.debugDetails}</p>
                                        )
                                    }

                                </React.Fragment>
                            )
                        }
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}