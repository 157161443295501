import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {Accordion, List, PageContainer, PageHeader, PageRow, SelectListGroup, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";
import styles from "./Doctors.module.scss";
import {BasePageParameters, ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import FenixAppController from "@/pages/FenixAppController";
import Localizer from "@/localization/Localizer";

export interface IDoctorsParams extends BasePageParameters {}

interface IDoctorsProps {}

interface IDoctorsState {
    doctors: DynamicPageData[];

    medicalFields: DynamicPageData[];

    selectedMedicalField: DynamicPageData | null;
}

export default class Doctors extends AnonymousPage<IDoctorsProps, IDoctorsState> {
    state: IDoctorsState = {
        doctors: [],
        medicalFields: [],
        selectedMedicalField: null
    };

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const response: DynamicPageResponse = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=doctors&populate=*");

        const medicalFields: DynamicPageResponse = await this.getAsync(
            "/api/Application/GetDynamicPageContent?populate=*&contentType=medical-fields&sort=name:asc"
        );

        if (response.data) {
            await this.setState({doctors: response.data});
        }
        if (medicalFields) {
            await this.setState({
                medicalFields: medicalFields.data.filter((item) => item.attributes.hideFromDoctorsListing != true),
                selectedMedicalField: medicalFields.data.filter((item) => item.attributes.externalId === "YLE")[0]
            });
        }
    }

    async getDynamicPageData(sender: List<DynamicPageData>): Promise<DynamicPageData[]> {
        const response: DynamicPageResponse = await sender.getAsync(
            "/api/Application/GetDynamicPageContent?populate=*&contentType=services"
        );
        if (response.data) {
            await this.setState({selectedMedicalField: response.data[0]});
        }

        return response.data;
    }

    private get selectedClinic(): DynamicPageData | null {
        return this.state.selectedMedicalField;
    }

    public getTitle(): string {
        return Localizer.doctorsPageTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader title={Localizer.doctorsPageTitle} className={styles.pageHeader} />

                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        <div className="row m-0 p-0">
                            <div id={"left_menu"} className={"col-md-4"} style={{paddingTop: "3rem"}}>
                                {this.state.medicalFields &&
                                    this.state.medicalFields.map((item) => {
                                        return (
                                            <div key={item.id}>
                                                <div
                                                    className={
                                                        this.state.selectedMedicalField == item
                                                            ? this.css(styles.selected, styles.listItem)
                                                            : styles.listItem
                                                    }
                                                    onClick={async () => {
                                                        await this.setState({selectedMedicalField: item});
                                                        if (ch.mobile) {
                                                            let objDiv = document.getElementById("left_menu");
                                                            window.scrollTo(0, objDiv?.scrollHeight ?? document.body.scrollHeight);
                                                        } else {
                                                            window.scrollTo(0, 0);
                                                        }
                                                    }}
                                                >
                                                    <img src={FenixAppController.getIcon(item)} height={40} width={40} alt="alt" />
                                                    <div>{item.attributes.name}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className={"col-md-8"} style={{paddingTop: "3rem"}}>
                                {this.state.selectedMedicalField && (
                                    <>
                                        <div className={styles.doctorsContainer}>
                                            {this.state.selectedMedicalField.attributes.doctors &&
                                                this.state.selectedMedicalField.attributes.doctors.data.map((field) => {
                                                    return (
                                                        <div
                                                            className={styles.doctor}
                                                            key={`doctor_${field.id}`}
                                                            onClick={async () =>
                                                                PageRouteProvider.redirectAsync(
                                                                    PageDefinitions.doctor.route({
                                                                        params: {selectedDoctor: field.attributes.externalId}
                                                                    })
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={this.getDoctorImage(field.attributes.externalId)}
                                                                className={styles.doctorImage}
                                                            />
                                                            <div className={styles.titleContainer}>
                                                                <h3>{field.attributes.firstName + " " + field.attributes.lastName}</h3>
                                                                <center>{field.attributes.title}</center>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }

    private getDoctorImage(externalId: string) {
        return (
            this.state.doctors.filter((item) => item.attributes.externalId == externalId)[0]?.attributes?.imageSmall?.data?.attributes
                ?.url ?? FenixAppController.defaultDoctorImage()
        );
    }
}
