import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
  Accordion,
  Button, ButtonType,
  IAccordionClassNames,
  PageContainer,
  PageHeader,
  PageRow
} from "@reapptor-apps/reapptor-react-components";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import styles from "./Pricing.module.scss";
import Localizer from "@/localization/Localizer";
import FenixAppController from "@/pages/FenixAppController";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import {IServicePageViewParams} from "@/pages/ServicePageView/ServicePageView";


export interface IPricingParams {}
interface IPricingState {
    data: DynamicPageData[];
}

export default class Pricing extends AnonymousPage<IPricingState> {
    state: IPricingState = {
        data: []
    };

    static accordionClassNames : IAccordionClassNames = {
        headerContainer: 'accordionHeaderContainer'
    }

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const response: DynamicPageResponse = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=price-lists&populateAll=true");

        await this.setState({data: response.data});
    }

    private renderPricingContent (item: DynamicPageData) {
        if(item.attributes.infoCard){
            return(
              <>
                  <div className={styles.pricingContentWithCardContainer}>
                      {item.attributes.content &&
                        <div className={styles.pricingContentTextDiv}>
                          <ReactMarkdown>{item.attributes.content}</ReactMarkdown>
                        </div>
                      }
                      {item.attributes.infoCard &&
                        <div className={styles.pricingInfoCard}>
                          <div className={styles.pricingInfoCardHeader}>
                            {item.attributes.infoCard.icon &&
                            <img src={item.attributes.infoCard.icon.data[0].attributes.url}/>
                            }
                            {item.attributes.infoCard.title &&
                              <span>{item.attributes.infoCard.title}</span>
                            }
                          </div>
                          <div className={styles.pricingInfoCardContent}>
                            {item.attributes.infoCard.content &&
                              <ReactMarkdown>{item.attributes.infoCard.content}</ReactMarkdown>
                            }
                          </div>
                          {item.attributes.infoCard.buttonEnabled &&
                            <div className={styles.pricingInfoCardButtonRow}>
                              <Button
                                label={item.attributes.infoCard.buttonText}
                                type={ButtonType.Primary}
                                icon={"fa-chevron-right"}
                                iconPosition={1}
                                onClick={() => Promise.resolve(item.attributes.infoCard?.buttonLinkDestination ? window.location.assign(item.attributes.infoCard?.buttonLinkDestination) : undefined)}
                              />
                            </div>
                          }
                        </div>
                      }
                  </div>
                  {item.attributes.additionalContent &&
                    <ReactMarkdown>{item.attributes.additionalContent}</ReactMarkdown>
                  }
              </>
            );
        }
        else{
            return(
              <>
                  {item.attributes.content &&
                    <ReactMarkdown>{item.attributes.content}</ReactMarkdown>
                  }
                  {item.attributes.additionalContent &&
                    <ReactMarkdown>{item.attributes.additionalContent}</ReactMarkdown>
                  }
              </>
            );
        }

    }

    public render(): React.ReactNode {
        return (
          <PageContainer fullWidth className={styles.pageContainer}>
              <PageHeader className={styles.pageHeader} title={Localizer.pricingPageTitle}/>
              <div className={styles.pageContent}>
                  <div className={styles.fluidContent}>
                      {this.state.data.map(item => {
                          return (
                            <div className={styles.pricingDiv} key={item.id}>
                                <h3>{item.attributes.title}</h3>
                                {this.renderPricingContent(item)}
                                {
                                    item.attributes.accordions.map(accordion => {
                                        return (
                                          <Accordion header={accordion.title}
                                                     autoCollapse={false}
                                                     className={styles.priceAccordion}
                                                     classNames={Pricing.accordionClassNames}
                                                     key={accordion.title}
                                          >
                                              <ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{accordion.content}</ReactMarkdown>
                                          </Accordion>

                                        )
                                    })
                                }
                            </div>
                          )
                      })
                      }
                  </div>
              </div>
          </PageContainer>
        );
    }
}
