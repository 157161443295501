import {BaseTransformProvider, Utility, GeoLocation, TFormat} from "@reapptor-apps/reapptor-toolkit";
import {SelectListGroup, SelectListItem, StatusListItem, ITitleModel} from "@reapptor-apps/reapptor-react-components";
import Organization from "../models/server/Organization";
import User from "../models/server/User";
import UserRole from "../models/server/UserRole";
import OrganizationContract from "../models/server/OrganizationContract";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../localization/Localizer";
import MedicalField from "@/models/cms/MedicalFieldsResponse";

class TransformProvider extends BaseTransformProvider {

    protected createSelectListItem(value: string, text: string, subtext: string, groupName?: string | null, favorite?: boolean | null): SelectListItem {
        const listItem = new SelectListItem(value, text, subtext);
        listItem.favorite = (favorite == true);
        if (groupName) {
            listItem.group = SelectListGroup.create(groupName);
        }
        return listItem;
    }

    public constructor() {
        super();
    }

    public toTitle(item: any): ITitleModel {

        let label: string | null = null;
        let description: string | null = null;

        if (item != null) {
            label = Utility.findStringValueByAccessor(item, ["label", "name"]);
            description = Utility.findStringValueByAccessor(item, ["description", "text"]);
        }

        return {
            description: description || "",
            label: label || "",
            icon: null
        };
    }

    public userToString(item: User): string {
        const firstname: string = (item.firstname != null) ? item.firstname : "";
        const lastName: string = (item.lastName != null) ? item.lastName : "";
        if (firstname || lastName) {
            return `${firstname} ${lastName}`.trim();
        }
        return item.username;
    }

    public organizationToString(item: Organization): string {
        return (item.vatId)
            ? `${item.name}, ${item.vatId}`
            : item.name;
    }

    public organizationContractToString(item: OrganizationContract): string {
        return (item.externalId)
            ? `${item.name}, ${item.externalId}`
            : item.name;
    }

    public locationToString(location: GeoLocation | null) {
        return (location != null)
            ? [location.address, location.postalCode, location.city].filter(item => !!item).join(", ")
            : "";
    }

    public toString(item: any, format?: TFormat | null): string {

        if (item == null) {
            return "";
        }

        if ((item instanceof Organization) || item.isOrganization) {
           return this.organizationToString(item as Organization);
        }

        if ((item instanceof OrganizationContract) || item.isOrganizationContract) {
            return this.organizationContractToString(item as OrganizationContract);
        }

        if ((item instanceof User) || (item.isUser === true)) {
            return this.userToString(item as User);
        }

        if ((item instanceof GeoLocation) || item.isGeoLocation) {
            return this.locationToString(item as GeoLocation);
        }

        return super.toString(item, format);
    }

    public toSelectListItem(item: any): SelectListItem {

        if ((item instanceof Organization) || (item.isOrganization)) {
            return this.toOrganizationListItem(item as Organization);
        }

        if ((item instanceof MedicalField) || item.isMedicalField) {
            return this.toMedicalFieldListItem(item as MedicalField);
        }

        if ((item instanceof OrganizationContract) || (item.isOrganizationContract)) {
            return this.toOrganizationContractListItem(item as OrganizationContract);
        }

        if ((item instanceof User) || (item.isUser)) {
            return this.toUserListItem(item as User);
        }

        if ((item instanceof UserRole) || (item.isUserRole)) {
            return this.toUserRoleListItem(item as UserRole);
        }

        return super.toSelectListItem(item) as SelectListItem;
    }

    public toUserListItem(item: User): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.toString(item);
        selectedItem.subtext = item.email || item.phone;
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = !item.isLocked;
        if(item.role != null && item.role.group != null){
            selectedItem.group = SelectListGroup.create(EnumProvider.getUserRoleGroupName(item.role.group));
        }

        return selectedItem;
    }

    public toUserRoleListItem(item: UserRole): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.roleName;
        selectedItem.text = Localizer.get(`RoleName.${item.roleName}`);
        selectedItem.group = SelectListGroup.create(EnumProvider.getUserRoleGroupName(item.group));
        return selectedItem;
    }

    public toOrganizationListItem(item: Organization): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.vatId;
        selectedItem.subtext = item.vatId;
        selectedItem.favorite = item.favorite;
        return selectedItem;
    }

    public toMedicalFieldListItem(item: MedicalField): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id.toString();
        selectedItem.text = item.attributes!.speciality;
        return selectedItem;
    }

    public toOrganizationContractListItem(item: OrganizationContract): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.externalId;
        selectedItem.subtext = item.externalId;
        selectedItem.favorite = item.favorite;
        return selectedItem;
    }
}

export default new TransformProvider();