import React from "react";
import AuthorizedPage from "../../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageContainer, WidgetContainer, RouteWidget} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../../localization/Localizer";

import athenaeumStyles from "../../../theme/Athenaeum.module.scss";

interface IDashboardProps {}

interface IDashboardState {}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {
    public getManual(): string {
        return Localizer.tasksDashboardGetManual;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent={this.desktop} className={athenaeumStyles.pageContainer} alertClassName={athenaeumStyles.alert}>
                <WidgetContainer>
                    <RouteWidget
                        icon={{name: "users"}}
                        route={PageDefinitions.contactSupportRoute}
                        label={"Contact support"}
                        description={"Description"}
                    />

                    <RouteWidget
                        icon={{name: "users-cog"}}
                        route={PageDefinitions.admin.route}
                        label={"Admin"}
                        description={"User management & settings"}
                    />
                </WidgetContainer>
            </PageContainer>
        );
    }
}
