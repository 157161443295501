import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
    Button,
    ButtonType,
    EmailInput, FileInput,
    Form, IStringInputModel,
    PageContainer,
    PageHeader, TextAreaInput,
    TextInput
} from "@reapptor-apps/reapptor-react-components";
import ReactMarkdown from "react-markdown";
import styles from "./Jobs.module.scss";
import Localizer from "@/localization/Localizer";
import {JobsData, JobsResponse} from "@/models/cms/JobsResponse";
import {BasePageParameters, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import Dictionary from "typescript-collections/dist/lib/Dictionary";

export interface IJobsParams extends BasePageParameters {}

interface IJobState {
    data: JobsData | null;
}

export default class Jobs extends AnonymousPage {
    state: IJobState = {
        data: null
    }

    public email: IStringInputModel = {value: ""};
    public phone: IStringInputModel = {value: ""};
    public firstname: IStringInputModel = {value: ""};
    public lastname: IStringInputModel = {value: ""};
    public message: IStringInputModel = {value: ""};
    public attachments: FileModel[] = [];
    public formRef: React.RefObject<Form> = React.createRef();

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const response: JobsResponse = await this.getAsync(
            "/api/Application/GetJobData"
        );
        await this.setState({data: response.data});
        window.scrollTo(0, 0);
    }

    private async openJobPosting(slug: string) {
        await PageRouteProvider.redirectAsync(PageDefinitions.jobPosting.route({params: {title: slug}}))
    }

    public render(): React.ReactNode {

        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader className={styles.pageHeader} title={Localizer.jobsPageTitle}/>
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        {this.state.data && (
                            <>
                                <div className={styles.jobText}>
                                    <ReactMarkdown>{this.state.data.attributes.content}</ReactMarkdown>
                                </div>
                                {
                                    this.state.data.attributes.job_postings != null && this.state.data.attributes.job_postings.data.length > 0 && (
                                        <div className={styles.openJobPostingsTitle}>
                                            <strong>{Localizer.jobsPageOpenPositions}</strong>
                                        </div>
                                    )
                                }

                                <div className={styles.openJobPostings}>
                                    {this.state.data.attributes.job_postings != null && this.state.data.attributes.job_postings.data.length > 0 && (
                                        this.state.data.attributes.job_postings.data.map(posting => {
                                            return (
                                                <Button
                                                    key={posting.id}
                                                    label={posting.attributes.title}
                                                    className={styles.jobPostingButton}
                                                    type={ButtonType.Text}
                                                    onClick={async () => {
                                                        this.openJobPosting(posting.attributes.slug)
                                                    }}/>
                                            )
                                        })

                                    )
                                    }
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </PageContainer>
        );
    }
}