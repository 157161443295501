import React from "react";
import {BaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import UserNotificationMessage from "@/models/server/UserNotificationMessage";
import Localizer from "@/localization/Localizer";

import styles from "./NotificationItem.module.scss";

export interface INotificationItemProps {
    className?: string;
    message: UserNotificationMessage;
    onClick?(sender: NotificationItem, message: UserNotificationMessage): Promise<void>;
}

export default class NotificationItem extends BaseComponent<INotificationItemProps> {

    private async invokeOnClickAsync(): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, this.message);
        }
    }
    
    public get message(): UserNotificationMessage {
        return this.props.message;
    }

    public render(): React.ReactNode {
        const activeStyle: any = (!this.message.read) && styles.active;
        const pageRouteStyle: any = (!!this.message.pageRoute) && styles.pageRoute;
        
        return (
            <div id={this.id} className={this.css(styles.notificationItem)}>
                <div className={this.css(styles.context, activeStyle, pageRouteStyle)} onClick={() => this.invokeOnClickAsync()}>
                    
                    <span className={styles.createdAt}>{this.message.createdAt.format("D")}</span>
                    <span className={styles.message}>{ReactUtility.toMarks(this.message.message)}</span>
                    {
                        (!!this.message.text) &&
                        (
                            <span>{ReactUtility.toMarks(this.message.text)}</span>
                        )
                    }
                    
                </div>
            </div>
        )
    }
}