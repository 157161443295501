import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Form, IStringInputModel, Modal, Spinner, TextInput} from "@reapptor-apps/reapptor-react-components";
import {Dictionary} from "typescript-collections";
import {PossibleAppointment} from "@/pages/AppointmentBooking/AppointmentBookingServicePointList/AppointmentBookingServicePointList";
import FenixAppController from "@/pages/FenixAppController";

import styles from './CancelAppointmentModal.module.scss'
import {LoginForm} from "@/components/LoginForm/LoginForm";
import ErpLoginResponse from "@/models/server/responses/ErpLoginResponse";
import Localizer from "@/localization/Localizer";

interface ICancelAppointmentModalProps {
    title?: string;

    onTimeReserved(sender: CancelAppointmentModal, appointment: PossibleAppointment): Promise<void>;
}

interface ICancelAppointmentModalState {

    isloading: boolean;

    appointmentDetails: IAppointmentDetails | null;

    cancelledAppointmentId: string | null;
}


interface IAppointmentDetails {

    id: string,
    clinic: string,
    doctor: string,

    error: string | null,
    speciality: string,
    date: string,
    time: string,
    description: string | null

}

export default class CancelAppointmentModal extends BaseComponent<ICancelAppointmentModalProps, ICancelAppointmentModalState> {

    state: ICancelAppointmentModalState = {
        isloading: false,
        appointmentDetails: null,
        cancelledAppointmentId: null
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    public firstname: IStringInputModel = {value: ""};
    public lastname: IStringInputModel = {value: ""};
    public ssn: IStringInputModel = {value: ""};

    public code: IStringInputModel = {value: ""};

    public cancellationForm: React.RefObject<Form> = React.createRef();
    public loginForm: React.RefObject<Form> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }


    public async openAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.openAsync();
        }
    }

    public async onOpenModalAsync(): Promise<void> {

    }

    public async closeAsync(): Promise<void> {
        await this.setState({cancelledAppointmentId: null, appointmentDetails: null})
        if (this.modal) {
            await this.modal.closeAsync();
        }
        await this.reRenderAsync();
    }

    private async handleCancelAppointment(data: Dictionary<string, any>): Promise<void> {
        await this.setState({isloading: true})

        const cancelled: boolean = await this.postAsync("/api/Application/CancelAppointment", this.state.appointmentDetails!.id);

        if (cancelled) {
            await this.setState({isloading: false, cancelledAppointmentId: this.state.appointmentDetails!.id})

            await this.reRenderAsync();

        } else {
            this.cancellationForm.current?.setValidationErrorsAsync(Localizer.cancelAppointmentModalFormError);
        }
    }

    private async handleGetDetailsAsync(data: Dictionary<string, any>): Promise<void> {
        const request = {} as any;
        data.keys().map((key) => {
            return (request[key] = data.getValue(key));
        });

        await this.setState({isloading: true})

        const appointmentDetails: IAppointmentDetails = await this.postAsync("/api/Application/GetReservedAppointmentDetails", this.code.value);

        if (appointmentDetails) {
            await this.setState({isloading: false, appointmentDetails})

            await this.reRenderAsync();
        }
    }

    public async handleLoginSubmit(data: Dictionary<string, any>): Promise<void> {
        const loginRequest = {} as any;
        data.keys().map((key) => {
            return (loginRequest[key] = data.getValue(key));
        });

        await this.setState({isloading: true})

        const response: ErpLoginResponse = await this.postAsync("/api/Application/ErpLogin", loginRequest);

        if (!response.failed) {
            await this.setState({isloading: false})

            await this.reRenderAsync();

        } else {
            this.loginForm.current?.setValidationErrorsAsync(Localizer.cancelAppointmentModalLoginFailed);
        }
    }


    public get isOpen(): boolean {
        return ((this._modalRef.current != null) && (this._modalRef.current.isOpen));
    }

    public get showLoginForm(): boolean {
        return !FenixAppController.userContext.user;
    }

    public get showGetInfoButton(): boolean {
        return (FenixAppController.userContext.user != null);
    }


    private get getTitle() {
        if (this.showLoginForm) {
            return Localizer.cancelAppointmentModalLoginToCancel
        }
        if (this.showGetInfoButton) {
            return Localizer.cancelAppointmentModalEnterBookingNumberToCancel
        }

        return "..."
    };

    public render(): React.ReactNode {
        return (
            <Modal className={styles.cancelAppointmentModal}
                   bodyClassName={this.css("")}
                   id={"qrModal"}
                   ref={this._modalRef}
                   title={this.getTitle}
                   onClose={() => this.closeAsync()}
                   onOpen={() => this.onOpenModalAsync()}
            >

                {
                    (this.showLoginForm) && (
                        <div className={""}>
                            <LoginForm loginRef={this.loginForm} formSubmit={async (data: Dictionary<string, any>) => await this.handleLoginSubmit(data)}/>
                        </div>
                    )
                }
                {
                    (this.showGetInfoButton) && (
                        <>
                            <Form onSubmit={async (sender, data) => this.handleGetDetailsAsync(data)}>
                                {
                                    (this.state.appointmentDetails?.error) && (
                                        <p>{Localizer.cancelAppointmentModalNoBookingFound}</p>
                                    )
                                }
                                <TextInput model={this.code} label={Localizer.cancelAppointmentModalBookingNumber}/>
                                <Button label={Localizer.cancelAppointmentModalGetInfo} submit type={ButtonType.Orange}/>
                            </Form>
                            {
                                (this.state.appointmentDetails && !this.state.appointmentDetails.error) && (
                                    <Form ref={this.cancellationForm} onSubmit={async (sender, data) => this.handleCancelAppointment(data)}>
                                        <div className={styles.confirmContainer}>{Localizer.cancelAppointmentModalBookingInfo}</div>
                                        <p>{Localizer.cancelAppointmentModalDoctor}: {this.state.appointmentDetails.doctor}</p>
                                        <p>{Localizer.cancelAppointmentModalSpecialty}: {this.state.appointmentDetails.speciality}</p>
                                        <p>{Localizer.cancelAppointmentModalTime}: {this.state.appointmentDetails.date} {this.state.appointmentDetails.time}</p>
                                        {
                                            this.state.cancelledAppointmentId && (
                                                <p><b>{Localizer.cancelAppointmentModalCancelSuccess}</b></p>
                                            )
                                        }
                                        <Button submit
                                                disabled={this.state.cancelledAppointmentId != null}
                                                label={Localizer.cancelAppointmentModalCancelSelectedBooking}
                                                type={ButtonType.Orange}/>
                                    </Form>
                                )
                            }
                        </>

                    )
                }

                {
                    this.state.isloading && (
                        <Spinner global noDelay/>
                    )
                }

            </Modal>
        )
    }
}